<template>
  <div>
    <v-data-table
      :value="selection"
      class="rounded-lg overflow-hidden"
      :class="{ 'elevation-2': elevation }"
      :headers="headers"
      checkbox-color="primary"
      :item-class="itemClass"
      :items="items"
      :show-select="showSelect"
      no-data-text="暂无更多数据！"
      :items-per-page="itemPerPage"
      :item-key="itemKey"
      :hide-default-footer="hideDefaultFooter"
      :hide-default-header="hideDefaultHeader"
      :show-expand="showExpand"
      :expanded.sync="expanded"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :single-expand="singleExpand"
      :multi-sort="multiSort"
      :page="modelValue"
      :single-select="singleSelect"
      @input="selectionChange"
    >
      <template #[`body.append`]="data">
        <slot name="append" v-bind="data"></slot>
      </template>
      <template #[`top`]="data">
        <slot name="top" v-bind="data"></slot>
      </template>
      <template #expanded-item="data">
        <slot name="expanded-item" v-bind="data"></slot>
      </template>
      <!--      <template #[`item.actions`]="data">-->
      <!--        <slot :name="[`item.actions`]" v-bind="data"></slot>-->
      <!--      </template>-->
      <!--      <template #[`item.runningStatusName`]="data">-->
      <!--        <slot :name="[`item.runningStatusName`]" v-bind="data"></slot>-->
      <!--      </template>-->
      <!--      <template #[`item.healthStatusName`]="data">-->
      <!--        <slot :name="[`item.healthStatusName`]" v-bind="data"></slot>-->
      <!--      </template>-->
      <!--      <template #[`item.statusName`]="data">-->
      <!--        <slot :name="[`item.statusName`]" v-bind="data"></slot>-->
      <!--      </template>-->
      <!--      <template #[`item.createdTime`]="data">-->
      <!--        <slot :name="[`item.createdTime`]" v-bind="data"></slot>-->
      <!--      </template>-->
      <!--      <template #[`item.roleVos`]="data">-->
      <!--        <slot :name="[`item.roleVos`]" v-bind="data"></slot>-->
      <!--      </template>-->
      <!--      <template #[`item.roleVos1`]="data">-->
      <!--        <slot :name="[`item.roleVos1`]" v-bind="data"></slot>-->
      <!--      </template>-->
      <!--      <template #[`item.icon`]="data">-->
      <!--        <slot :name="[`item.icon`]" v-bind="data"></slot>-->
      <!--      </template>-->
      <!--      <template #[`item.status`]="data">-->
      <!--        <slot :name="[`item.status`]" v-bind="data"></slot>-->
      <!--      </template>-->
      <!--      <template #[`item.avatar`]="data">-->
      <!--        <slot :name="[`item.avatar`]" v-bind="data"></slot>-->
      <!--      </template>-->
      <!--      <template #[`item.violationFlag`]="data">-->
      <!--        <slot :name="[`item.violationFlag`]" v-bind="data"></slot>-->
      <!--      </template>-->
      <!--      <template #[`item.statusCode`]="data">-->
      <!--        <slot :name="[`item.statusCode`]" v-bind="data"></slot>-->
      <!--      </template>-->
      <template
        v-for="slotName in columnsSlotsArr"
        #[`item.${slotName}`]="data"
      >
        <slot
          v-if="$scopedSlots[slotName]"
          :name="slotName"
          v-bind="data"
        ></slot>
      </template>
    </v-data-table>
    <v-pagination
      v-if="total > 0 && paginationshow"
      v-model="modelValue"
      class="mt-5"
      next-icon="mdi-arrow-right-thin"
      prev-icon="mdi-arrow-left-thin"
      :length="pageLength"
      :total-visible="6"
    ></v-pagination>
  </div>
</template>
<script>
export default {
  name: 'KCrudTable',
  model: {
    prop: 'selection',
    event: 'change',
  },
  props: {
    value: { type: [Number], default: 1 },
    elevation: { type: [Boolean], default: true },
    singleSelect: { type: [Boolean], default: true },
    selection: { type: Array, default: () => [] },
    itemPerPage: { type: Number, default: 10 },
    total: { type: Number, default: 1 },
    headers: { type: Array, default: null },
    items: { type: Array, default: null },
    itemKey: { type: String, default: '' },
    showSelect: { type: Boolean, default: false },
    itemClass: { type: String, default: '' },
    hideDefaultFooter: { type: Boolean, default: true },
    hideDefaultHeader: { type: Boolean, default: false },
    showExpand: { type: Boolean, default: false },
    singleExpand: { type: Boolean, default: false },
    multiSort: { type: Boolean, default: false },
    expanded: { type: Array, default: () => [] },
    // options: { type: Array, default: () => [] },
    pageChanged: { type: Function, default: () => {} },
    sort: { type: Function, default: () => {} },
    paginationshow: { type: Boolean, default: true },
    sortBy: { type: [String, Array], default: () => '' },
    sortDesc: { type: [String, Array], default: () => '' },
  },
  data() {
    return {
      modelValue: 1,
      options: {},
    };
  },
  computed: {
    //todo插槽分发
    columnsSlotsArr() {
      const allSlots = this.headers.map((item) => item.value);
      const scopeSlots = Object.keys(this.$scopedSlots);
      return allSlots.filter((item) => scopeSlots.includes(item));
    },
    pageLength() {
      return this.total % this.itemPerPage === 0
        ? this.total / this.itemPerPage
        : Math.floor(this.total / this.itemPerPage) + 1;
    },
  },
  watch: {
    value() {
      this.modelValue = this.value;
    },
    modelValue() {
      this.$emit('input', this.modelValue);
      this.$emit('pageChanged', this.modelValue);
    },
    options() {
      this.$emit('sort', this.options);
    },
  },
  mounted() {
    this.modelValue = this.value;
  },
  methods: {
    selectionChange(e) {
      this.$emit('change', e);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep
.theme--light.v-data-table
> .v-data-table__wrapper
> table
> tbody
> tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #f6f9fb !important;
}
::v-deep .v-data-table-header {
  background: #f9f9f9;
}
::v-deep
.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th {
  font-size: 14px;
  font-weight: 800;
  color: #303133;
}
::v-deep.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr:last-child
> th {
  border: none;
}
::v-deep .v-data-table-header {
  tr {
    th {
      white-space: nowrap !important;
    }
  }
}
</style>
