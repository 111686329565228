color: this.$vuetify.theme.themes.light.bluegrey,
<!--explain：;@author: cy; @version: 1.0; date: 2022/3/25;-->
<template>
  <v-btn
    class="bt"
    :color="color"
    depressed
    :disabled="disabled"
    small
    :loading="loading"
    :icon="isicon"
    @click="$emit('click')"
  >
    <v-icon> {{ icon }}</v-icon>
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  name: 'KTableBluegreyBtn',
  props: {
    text: { type: String, default: '' },
    icon: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    isicon: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      color: this.$vuetify.theme.themes.light.blue,
    };
  },
  computed: {
    iconc() {
      return this.icon === '' ? '' : this.icon;
    },
  },
};
</script>

<style lang="scss" scoped>
.bt {
  color: #2e71fe !important;
}
</style>
