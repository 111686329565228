import http from '../http';

const path = '/fds/organization';
export default {
  // getOrganization(data) {
  //   return http.post(path + '/page', data, {
  //     'Content-Type': 'application/json;charset=UTF-8',
  //   });
  // },
  getOrganization(data) {
    return http.post(path + '/page', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  addOrganization(data) {
    return http.post(path + '/add', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  editOrganization(data) {
    return http.post(path + '/update', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  deleteOrganization(data) {
    return http.post(path + '/delete', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  getOrganizationDetail(uuid) {
    return http.get(path + '/info', uuid);
  },
  getSingleOrganizationDetail(uuid) {
    return http.get(path + '/singleOrganizationDetail', uuid);
  },
  // 初期下拉框值获取
  getSelectInit() {
    return http.get(path + '/area-list');
  },
  //部门树形结构
  getOrTree(data) {
    return http.get(path + '/tree', data);
  },
  //数据字典
  getEnum() {
    return http.get(path + '/enum-dict');
  },
};
