import http from '../http';

const path = '/api/MsgLog';
export default {
  getMsgLog(data) {
    return http.post(path + '/getMsgLog', data);
  },
  updateMsgReadStatus(data) {
    return http.post(path + '/updateMsgReadStatus', data);
  },
};
