<template>
  <div v-if="isShow" :class="getClass">
    <div class="toast-message"><span :class="getType"></span>{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    message: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShow: false,
      aniShow: false,
    };
  },
  computed: {
    getClass() {
      return `toast-card ${this.type} ${this.aniShow ? 'show' : 'hide'}`;
    },
    getType() {
      switch (this.type) {
        case 'error':
          return 'mdi mdi-bug';
        case 'warning':
          return 'mdi mdi-alert';
        case 'success':
          return 'mdi mdi-check-circle';
        default:
          return 'mdi mdi-email';
      }
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    show() {
      this.isShow = true;
      this.aniShow = true;
    },
    hide() {
      this.aniShow = false;
      setTimeout(() => {
        this.isShow = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-card {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: max-content;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding: 7px 14px 7px 7px;
  border-radius: 0 4px 4px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  margin-left: -100%;

  .toast-message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.toast-card.show {
  animation: show 0.3s forwards;
}

.toast-card.hide {
  animation: hide 0.3s forwards;
}

@keyframes show {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0;
  }
}

@keyframes hide {
  from {
    margin-left: 0;
  }
  to {
    margin-left: -100%;
  }
}

.toast-card.success {
  background-color: #51a351 !important;
}

.toast-card.warning {
  background-color: #f89406 !important;
}

.toast-card.error {
  background-color: #da4f49 !important;
}
</style>
