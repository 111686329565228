import utils from './index';
import * as echarts from 'echarts';
import api from '../api';
import http from '@/api/http';
import directives from './directives';
import httpConfig from '@/config/http.config';
const install = function (Vue) {
  Vue.use(directives);
  Vue.prototype.$util = utils;
  Vue.prototype.$echarts = echarts;
  Vue.prototype.$api = api;
  Vue.prototype.$bus = new Vue();
  Vue.prototype.$http = http;
  Vue.prototype.$businessApi = httpConfig.businessApi;
  Vue.prototype.$businessISG = httpConfig.businessISG;
  Vue.prototype.$imgUrl = httpConfig.imgUrl;
};

export default {
  install,
};
