/**
 * @author bbx
 */

export default {
  keyBoard: [
    {
      name: 'login',
      keyBindMap: {
        Enter: 'loginSubmitHandle',
      },
    },
  ],
};
