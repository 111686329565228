import http from '../http';

const path = '/upms/user';
export default {
  getUser(data) {
    return http.post(path + '/pageList', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  editUser(data) {
    return http.post(path, data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  addUser(data) {
    return http.put(path, data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  deleteUser(data) {
    return http.delete(path, data);
  },
};
