import http from '../http';

const path = '/fds/api/mine';
const paths = '/fds/api';
const pathing = '/fds/api/license';
export default {
  getenum() {
    return http.get(pathing + '/enum');
  },
  getStatus() {
    return http.get(pathing + '/status-query');
  },
  //个人中心任务名称卡把单独接口
  getcounter(data) {
    return http.get(path + '/item-counter', data);
  },
  //个人中心任务名称卡把调取任务名称
  getKaba(data) {
    return http.get(path + '/item-kaba-task', data);
  },
  //个人中心列表详情
  getrecordInfo(data) {
    return http.get(path + '/record-info', data);
  },
  //个人中心任务名称
  getoperate(data) {
    return http.get(path + '/operate-records', data);
  },
  //获取行领导查库
  getinsp(data) {
    return http.get(path + '/items-room-insp', data);
  },
  //获取清机
  getclean(data) {
    return http.get(path + '/items-clean', data);
  },
  //获取晨会
  getmonmeet(data) {
    return http.get(path + '/items-monmeet', data);
  },

  //个人中心title
  getEnumdict(data) {
    return http.get(path + '/items/enum-dict', data);
  },
  getItems(data) {
    return http.get(path + '/getItems', data);
  },
  getmessger(data) {
    return http.get(path + '/messages', data);
  },
  getread(data) {
    return http.get(path + '/read', data);
  },
  getCounterFlg(data) {
    return http.get(path + '/getCounterFlg', data);
  },
  getVideo(data) {
    return http.get(path + '/record-file', data);
  },
  // startTask(data) {
  //   return http.put(path + '/startTask', data, {
  //     'Content-Type': 'application/json;charset=UTF-8',
  //   });
  // },
  startTask(data) {
    return http.post(paths + '/data/task/start', data, {});
  },
  endTask(data) {
    return http.post(paths + '/data/task/end', data);
  },
  reStartTask(data) {
    return http.post(paths + `/data/task/re-start/${data}`, {});
  },
  endTaskInList(data) {
    return http.get(path + '/endTaskInList', data);
  },
};
