/*explain：;@author: cy; @version: 1.0; date: 2022/3/29;*/
import Vue from 'vue';
import Vuetify from '@/plugins/vuetify';
import KAlert from '@/components/koi/KAlert.vue';
const alertConstructor = Vue.extend(KAlert);
let seed = 1;
let instance;
let instances = [];
let VNode = alertConstructor.constructor;
const isVNode = (obj) => obj instanceof VNode;
function create(comp, obj) {
  let vm = new comp({ data: obj });
  vm.$vuetify = Vuetify.framework;
  vm = vm.$mount();
  return vm;
}
const Alert = function (options) {
  // eslint-disable-next-line no-debugger
  // debugger;
  options = Object.assign({}, options);

  const id = 'alert' + seed++;
  options.onClose = function () {
    Alert.close(id);
  };
  instance = alertConstructor;
  instance = create(instance, options);
  instance.id = id;
  instance.visible = true;
  instance.dom = instance.$el;

  let verticalOffset = options.offset || 0;
  instances.forEach((item) => {
    verticalOffset += item.$el.offsetHeight + 16;
  });
  verticalOffset += 16;
  instance.verticalOffset = verticalOffset + 'px';
  const app = document.getElementById('app');
  app.appendChild(instance.$el);
  instances.push(instance);
  return instance;
};
['success', 'warning', 'info', 'error'].forEach((type) => {
  Alert[type] = (options) => {
    if (typeof options === 'string' || isVNode(options)) {
      options = {
        content: options,
      };
    }
    options.type = type;
    return Alert(options);
  };
});
Alert.close = function (id, userOnClose) {
  let index = -1;
  // const len = instances.length;
  const instance = instances.filter((instance, i) => {
    if (instance.id === id) {
      index = i;
      return true;
    }
    return false;
  })[0];
  if (!instance) return;

  if (typeof userOnClose === 'function') {
    userOnClose(instance);
  }
  // eslint-disable-next-line no-debugger
  // debugger;
  instances.splice(index, 1);
};

export default Alert;
