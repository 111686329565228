/**
 * type:"interval"||"timeout"
 * @author bbx
 */
import httpConfig from './http.config';
// import store from '../store';
import api from '../api';
// import uiUtils from '../utils/ui';

export default {
  timers: [
    {
      name: httpConfig.httpTimer.heartbeat.name,
      handle: () => {
        api.system.account.heartbeat({});
      },
      timeout: httpConfig.httpTimer.heartbeat.timeOut,
      type: 'interval',
    },
    // {
    //   name: httpConfig.httpTimer.messageListen.name,
    //   handle: () => {
    //     store.dispatch('getMessage');
    //   },
    //   ImmediatelyStart: store.getters.isLogin,
    //   startDoFirst: true,
    //   timeout: httpConfig.httpTimer.messageListen.timeOut,
    //   type: 'timeout',
    // },
  ],
};
