import 'babel-polyfill'
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import mixin from './mixin';
import timer from './utils/data/timer';
import timerConfig from './config/timer.config';
import funs from './utils/funs';
import KAlert from '@/components/KAlert.js';
import components from '@/components/koi/index.js'; //导入全部自定义封装组件
import 'swiper/swiper-bundle.css'
import VideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';
import '@mdi/font/css/materialdesignicons.css';

components(Vue); //传入Vue实列，内部注册
Vue.use(VideoPlayer);
// process.env.NODE_ENV == 'development' && require('./mock/index.js');
Vue.config.productionTip = false;
Vue.mixin(mixin);
Vue.use(funs);
Vue.use(KAlert);
Vue.prototype.$alert = KAlert;
timer.initTimerConfig(timerConfig.timers);

import util from './utils/install';
Vue.use(util);
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
