<template>
  <div class="d-flex align-center" :style="cssVars">
    <div v-if="label" class="mr-2 text-right input-label align-self-start">
      {{ label }}:
    </div>
    <v-textarea
      v-model="modelValue"
      dense
      outlined
      label=""
      :maxlength="maxLength"
      :clearable="clearable"
      :rules="rules"
      :placeholder="defaultPlaceholder"
      :type="type"
      :disabled="disabled"
      class="input-field"
      @change="$emit('change')"
    />
  </div>
</template>
<script>
export default {
  name: 'KTextInput',
  props: {
    value: { type: [String, Number], default: '' },
    text: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    inputWidth: { type: String, default: '200px' },
    rules: { type: Array, default: () => [] },
    maxLength: { type: String, default: '20' },
    clearable: { type: Boolean, default: true },
    type: { type: String, default: 'text' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      modelValue: null,
      emptyArray: [],
    };
  },
  computed: {
    defaultPlaceholder() {
      return this.placeholder === '' ? '请输入' + this.label : this.placeholder;
    },
    cssVars() {
      return {
        '--labelWidth': this.labelWidth,
        '--inputWidth': this.inputWidth,
      };
    },
  },
  watch: {
    value() {
      this.modelValue = this.value;
    },
    modelValue() {
      this.$emit('input', this.modelValue);
    },
  },
  mounted() {
    this.modelValue = this.value;
  },
};
</script>
<style lang="scss" scoped>
.input-field {
  width: var(--inputWidth);
}
.input-label {
  width: var(--labelWidth);
}
//让错误浮动的显示在入力框下面，并不占用页面空间。
::v-deep .v-text-field__details {
  position: absolute;
  top: 3em;
}
</style>
