<template>
  <div class="d-flex align-center" :style="cssVars">
    <div v-if="label" class="mr-2 text-right input-label">{{ label }}:</div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="320px"
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="dateRangeText"
          dense
          outlined
          label=""
          :placeholder="defaultPlaceholder"
          persistent-hint
          v-bind="attrs"
          clearable
          @click:clear="modelValues"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="modelValue"
        :disabled="disabled"
        range
        no-title
        color="primary"
        @change="modelValues($event)"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: 'KDateRangeSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [Array, Number, Function], default: () => [] },
    text: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    inputWidth: { type: String, default: '200px' },
    rules: { type: Array, default: () => [] },
    maxLength: { type: String, default: '20' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      menu: false,
      dateRangeText: [],
      modelValue: [],
      emptyArray: [],
    };
  },
  computed: {
    defaultPlaceholder() {
      return this.placeholder === '' ? '请选择' + this.label : this.placeholder;
    },
    // dateRangeText: {
    //   get() {
    //     return this.modelValue.join(' ~ ');
    //   },
    //   set(newvalue) {
    //     console.log(newvalue);
    //     this.modelValue = [];
    //   },
    // },
    cssVars() {
      return {
        '--labelWidth': this.labelWidth,
        '--inputWidth': this.inputWidth,
      };
    },
  },
  watch: {
    value(v) {
      if (v?.length) {
        this.modelValue = this.value;
      }
    },
  },
  methods: {
    modelValues(val) {
      // eslint-disable-next-line no-debugger
      if (val?.length) {
        const date = val;

        const startDate = Date.parse(date[0]);
        if (date.length === 2) {
          const entDate = Date.parse(date[1]);
          if (entDate < startDate) {
            this.$alert.warning('结束日期不能大于开始日期');
            return;
          } else {
            this.dateRangeText = val;
            this.$emit('change', this.dateRangeText);
          }
        }
      } else {
        this.modelValue = [];
        this.$emit('change', []);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.input-field {
  width: var(--inputWidth);
}
.input-label {
  width: var(--labelWidth);
}
//让错误浮动的显示在入力框下面，并不占用页面空间。
::v-deep .v-text-field__details {
  position: absolute;
  top: 3em;
}
</style>
