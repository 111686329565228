import http from '../http';

const path = '/upms/menu';
export default {
  getMenu(data) {
    return http.get(path + '/tree', data);
  },
  editMenu(data) {
    return http.post(path, data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  addMenu(data) {
    return http.put(path, data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  deleteMenu(data) {
    return http.delete(path, data);
  },
};
