import router from '../../router';
import lodash from 'lodash';
import store from '../../store';
import httpConfig from '../../config/http.config';
import exception from '../exception';
import Vue from 'vue';
import KAlert from '@/components/KAlert';

export default {
  URL: {
    getParam() {
      const location = router.currentRoute || {};
      let params = {
        ...location.query,
      };
      if (location.params != {}) {
        Object.assign(params, location.params);
      }
      return lodash.cloneDeep(params);
    },
    toTimestamp() {},
  },
  resultHandle(res) {
    return new Promise((resolve, reject) => {
      if (res.code === 0 || res.code === 200) {
        resolve(res);
      } else if (res.code === 413) {
        if (store.getters.isLogin) {
          exception.toastError(`${res.message}`);
          store.dispatch('logout', {});
        }
      } else if (res.code === -1) {
        if (res.message === 'relogin') {
          store.dispatch('logoutWithoutResetRoute', {});
          Vue.prototype
            .dialog({
              title: '警告',
              content: '由于长时间无活动，系统为您自动登出，请确定以重新登陆',
              showClose: false,
            })
            .then(() => {
              router.push({ name: '/' });
              router.resetRoute();
            });
        } else {
          KAlert.error(res.message ? res.message : '未知错误');
          reject(res);
        }
      } else {
        exception.toastError(`${res.code}:${res.message}`);
        reject(res);
      }
    });
  },
  getSystemHeader() {
    let header = {};
    // header[httpConfig.header.ACCESS_ORIGIN] = router.currentRoute.name;
    header[httpConfig.header.ACCESS_TOKEN] = store.getters.token;
    return header;
  },
};
