import http from '../http';

const path = '/upms/account';
export default {
  heartbeat() {
    return http.post(path + '/heartbeat');
  },
  login(data) {
    return http.post(path + '/login', data);
  },
  logout(data) {
    return http.post(path + '/logout', data);
  },
  getAccount(data) {
    return http.get(path, data);
  },
  delMore(data) {
    return http.post('/upms/user/delete', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  getUserMsg(data) {
    return http.get('/fds/api/mine/msg-unread', data);
  },
  changePwd(data) {
    return http.post('/fds/api/mine/change-password', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  addAccount(data) {
    return http.post(path, data);
  },
  editAccount(data) {
    return http.put(path, data);
  },
  deleteAccount(data) {
    return http.delete(path, data);
  },
  getPermissionButton(data) {
    return http.get('/msapp-upms/permission/test/list', data);
  },
  checkOne(data) {
    return http.get('/upms/user/check-work', data);
  },
  getRoleRoute(data) {
    return http.get(path + '/role/route', data);
  },
  getRoleRoutePermission(data) {
    return http.get(path + '/role/route/permission', data);
  },
  // addRole(data) {
  //   return http.post(path + '/role', data);
  // },
  // editRole(data) {
  //   return http.put(path + '/role', data);
  // },
  // editRoleRoutePermission(data) {
  //   return http.put(path + '/role/route/permission', data);
  // },
  // deleteRole(data) {
  //   return http.delete(path + '/role', data);
  // },
  getRoute(data) {
    return http.get(path + '/route', data);
  },
  getRouteGlobal(data) {
    return http.get(path + '/route/global', data);
  },
  getRoutePermission(data) {
    return http.get(path + '/route/permission', data);
  },
  addRoute(data) {
    return http.post(path + '/route', data);
  },
  editRoute(data) {
    return http.put(path + '/route', data);
  },
  editRoutePermission(data) {
    return http.put(path + '/route/permission', data);
  },
  deleteRoute(data) {
    return http.delete(path + '/route', data);
  },
  getPermission(data) {
    return http.get(path + '/permission', data);
  },
  getPermissionGlobal(data) {
    return http.get(path + '/permission/global', data);
  },
  addPermission(data) {
    return http.post(path + '/permission', data);
  },
  editPermission(data) {
    return http.put(path + '/permission', data);
  },
  deletePermission(data) {
    return http.delete(path + '/permission', data);
  },
};
