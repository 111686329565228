/* eslint-disable no-unused-vars */
import {mapState, mapGetters} from 'vuex';
import lodash from 'lodash';
import regular from '@/utils/data/regular';
import moment from 'moment';
import validate from '@/mixin/validate';
import Vue from 'vue';
// import gradeEnum from '@/enums/api/classCollective/gradeEnum';
// import exception from '@/utils/exception';

const mixin = {
  data() {
    return {
      systemName: process.env.VUE_APP_TITLE,
      restDataSource: null,
      cruddataMX: {
        crud: {},
        apis: {},
        defaultConditions: {}, // 默认检索条件
        conditions: {}, // 修改后的检索条件
        dataList: [], // 一览数据
        ascList: [], // 升序
        descList: [], // 降序
        data: {}, // 新建，编辑，详细时使用的对象
        subParam: {},
        runAction: () => {
        }, //新建，编辑时，打开对话框后，按下确定后执行新建，编辑更新API操作。
        callbackAfterInit: () => {
        },
        loading: false, // 加载中的指示FLG
        scrollWidth: 1300,
        editingPage: false, // 是否显示编辑中的页面或Popup
        editingIndex: -1, // 编辑中条目的Index号码
        defaultItem: {},
        pagination: {
          current: 1,
          defaultPageSize: 10,
          total: 0,
        },
      },
      //todo 全局的校验
      globalRules: {
        empty: (v) => !!v || '不能为空',
        multiple: (v) => !!v.length || '至少选一个',
        ip: (v) => validate.IP(v),
        phone: (v) => validate.phone(v),
        phoneNew: (v) => validate.phoneNew(v),
        number: (v) => validate.Number(v),
        idCard: (v) => validate.IDCard(v),
        lengthTest: (v, len) => validate.lengthTest(v, len),
        repeatTest: (v, key, list) => validate.repeatTest(v, key, list),
        likeTest: (v, key, list, msg) => validate.likeTest(v, key, list, msg),
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$router.setPathJob(vm, to);
    });
  },
  mounted() {
    // console.log(this.$route);
  },
  created() {
  },
  computed: {
    ...mapState({
      accountState: (state) => state.loginStore.account,
    }),
    ...mapGetters({
      permission: 'permission',
    }),
  },
  methods: {
    crudInitMX(
      crud,
      apis,
      defaultConditions,
      subParam,
      createNotInit,
      callbackAfterInit
    ) {

      this.restDataSource = lodash.cloneDeep(crud);
      //todo 深拷贝一份原始数据为重置编辑弹窗数据
      this.cruddataMX.apis = apis;
      this.cruddataMX.subParam = subParam || {};
      this.cruddataMX.data = {...crud.default} || {};
      console.log(createNotInit, 2)
      //todo 这里预置新增编辑页面所要的默认数据
      if (callbackAfterInit) {
        this.cruddataMX.callbackAfterInit = callbackAfterInit;
      }
      if (!lodash.isEmpty(defaultConditions)) {
        this.cruddataMX.defaultConditions = lodash.cloneDeep(defaultConditions);
        this.cruddataMX.conditions = lodash.cloneDeep(defaultConditions);
      }
      if (!createNotInit) {
        this.crudActionMX().list();
      }
    },
    crudConditionsMX() {
      const {cruddataMX} = this;
      return {
        get: () => {
          this.cruddataMX.conditions.pageNo = cruddataMX.pagination.current;
          this.cruddataMX.conditions.maxResults =
            cruddataMX.pagination.defaultPageSize;
          this.cruddataMX.conditions.ascList = cruddataMX.ascList;
          this.cruddataMX.conditions.descList = cruddataMX.descList;
          return cruddataMX.conditions;
        },
        resetPage: () => {
          this.cruddataMX.pagination.current = 1;
          this.crudActionMX().list();
        },
        reset: () => {
          this.cruddataMX.ascList = [];
          this.cruddataMX.descList = [];

          this.$set(
            this.cruddataMX,
            'conditions',
            lodash.cloneDeep(this.cruddataMX.defaultConditions)
          );

          this.cruddataMX.pagination.current = 1;
          this.crudActionMX().list();
        },
        subParamHandle: (data, subParam) => {
          let param = lodash.cloneDeep(data);
          if (subParam && subParam.length > 0) {
            param = {};
            subParam.push('pageNo');
            subParam.push('maxResults');
            subParam.push('ascList');
            subParam.push('descList');
            subParam = Array.from(new Set(subParam));
            for (let p in subParam) {
              const key = subParam[p];
              // if (data[key]) { todo 保证所有数据正常传递出去 值为 null false undefined 0 不能正常处理
              param[key] = data[key];
              // }
            }
          }
          return param;
        },
      };
    },
    crudPaginationMX() {
      return {
        total: (total) => {
          this.cruddataMX.pagination.total = total;
        },
        change: (page) => {
          this.cruddataMX.pagination.current = page;
          this.crudActionMX().list();
        },
        sort: (options) => {
          this.cruddataMX.ascList = [];
          this.cruddataMX.descList = [];
          for (var i = 0; i < options.sortBy.length; i++) {
            if (options.sortDesc[i]) {
              this.cruddataMX.descList.push(options.sortBy[i]);
            } else {
              this.cruddataMX.ascList.push(options.sortBy[i]);
            }
          }
          this.crudActionMX().list();
        },
      };
    },
    crudActionMX() {
      const {
        cruddataMX,
        crud,
        restDataSource,
        crudActionMX,
        crudPaginationMX,
        crudConditionsMX,
      } = this;

      const runAction = (crud, data, option) => {
        crud.switch = true;
        cruddataMX.loading = true;
        return () => {
          if (crud.loading === false) {
            // crud.loading = true;
          }
          const sub = () => {
            crud.loading = true;
            this.$store.commit('SET_LOAD', true);
            // eslint-disable-next-line no-debugger
            return new Promise((resolve, reject) => {
              if (option.api) {
                let param = this.crudConditionsMX().subParamHandle(
                  data,
                  option.subParam
                );
                option
                  .api(param)
                  ?.then((res) => {
                    resolve(res);
                    this.crudActionMX().list();
                  })
                  .catch((error) => {
                    reject(error);
                  })
                  .finally(() => {
                    if (crud.loading) {
                      crud.loading = false;
                      this.$store.commit('SET_LOAD', false);
                    }
                  });
              } else {
                if (crud.loading) {
                  crud.loading = false;
                }
                crud.switch = false;
                reject(`No ${option.name} api found!`);
              }
            });
          };
          return new Promise((resolve, reject) => {
            if (crud?.form?.ref) {
              if (this.$refs[crud.form.ref].validate()) {
                sub()
                  .then((res) => {
                    resolve(res);
                    //todo 统一提示内容
                    this.$alert.success(res.message || '操作成功');
                    crud.switch = false;
                    cleanValidation();
                  })
                  .catch((error) => {
                    // cleanValidation();
                    reject(error);
                  });
              } else {
                if (crud.loading) {
                  crud.loading = false;
                }
                reject('form validation failed.');
              }
            } else {
              sub()
                .then((res) => {
                  resolve(res);
                  cleanValidation();
                })
                .catch((error) => {
                  cleanValidation();
                  reject(error);
                });
            }
          });
        };
      };

      const cleanValidation = () => {
        return new Promise(() => {
          this.$nextTick(() => {
            if (crud?.form?.ref && this.$refs[crud.form.ref]) {
              this.$refs[crud.form.ref].resetValidation();
              this.cruddataMX.data = {...restDataSource.default}; //todo 重置所有数据
            }
          });
        });
      };

      const closeEPage = () => {
        return new Promise(() => {
          this.$nextTick(() => {
            cruddataMX.editingIndex = -1;
            cruddataMX.data = Object.assign({}, cruddataMX.defaultItem);
            console.log('closeEditingPage', cruddataMX.data);
          });
        });
      };

      return {
        // open crud.
        addActionPrepare: (crud, data, onOpen) => {
          if (typeof onOpen === 'function') {
            onOpen();
          }
          // data = lodash.cloneDeep(data);
          // this.cruddataMX.data = data || {};
          crud.title = `新增${crud.name}`;
          this.cruddataMX.runAction = runAction(crud, data, {
            api: this.cruddataMX.apis.add,
            name: `add ${crud.name}`,
            subParam: this.cruddataMX.subParam.add,
          });
        },
        // open crud.
        editActionPrepare: async (crud, data, onOpen) => {
          if (typeof onOpen === 'function') {
            await onOpen(); //将详情接口写在该方法内
          } else {
            //todo 兼容不传onOpen参数时回显信息
            Object.assign(this.cruddataMX.data, data);

          }
          const fn = (sources) => {
            crud.title = `修改${crud.name}`;
            this.cruddataMX.runAction = runAction(crud, sources, {
              api: this.cruddataMX.apis.edit,
              name: `edit ${crud.name}`,
              subParam: this.cruddataMX.subParam.edit,
            });
          };
          // todo onOpen方法在页面内应该是个匿名函数，写法参考 src/views/basicdata/organization/organization.vue
          await fn(this.cruddataMX.data);
        },
        // open crud and run api immediately.
        details: (crud, uuid, onOpen, formatFuc) => {
          /*
           * @param formatFuc { Function } 用于处理接口格式化数据 可选
           * */
          if (typeof onOpen === 'function') {
            onOpen();
          }
          crud.title = `查看${crud.name}`;
          if (crud.loading === false) {
            crud.loading = true;
          }
          this.cruddataMX.data = {};
          if (!cruddataMX.apis.details || lodash.isEmpty(uuid)) {
            this.$util.ui.crudDelayClose(crud, () => {
            }, {});
            return;
          }
          cruddataMX.apis
            .details({
              uuid: uuid,
            })
            .then((res) => {
              if (typeof formatFuc === 'function') {
                this.cruddataMX.data = formatFuc(res.data[0]) || {};
              } else {
                this.cruddataMX.data = res.data[0] || {};
              }
              crud.switch = true;
            })
            .finally(() => {
              if (crud.loading) {
                crud.loading = false;
              }
            });
        },
        // run api immediately
        delete: (item) => {
          // if delete api is not valid. ends quietly.
          if (!this.cruddataMX.apis.delete) {
            return;
          }
          this.cruddataMX.loading = true;
          // delete api only take "uuid" parameter
          this.cruddataMX.apis
            .delete(item)
            .then(({code, message}) => {
              if (code === 200 || code === 0) {
                this.$alert.success(message);
              }
              if (this.cruddataMX.dataList.length === 1) {
                this.cruddataMX.pagination.current--
              }
              this.crudActionMX().list();
            })
            .finally(() => {
              this.cruddataMX.loading = false;
            });
        },
        // run api immediately
        list: () => {
          // if list api is not valid. ends quietly.
          if (!cruddataMX.apis.list) {
            return;
          }
          this.cruddataMX.loading = true;
          let param = this.crudConditionsMX().subParamHandle(
            crudConditionsMX().get(),
            this.cruddataMX.subParam.list
          );
          cruddataMX.apis
            .list(param)
            .then((res) => {
              // crudPaginationMX().total(res.total);
              // this.cruddataMX.dataList = res.data;
              //列表单独修改用formalist
              if (cruddataMX.apis?.formalist) {
                crudPaginationMX().total(res.total);
                this.cruddataMX.dataList = cruddataMX.apis.formalist(res.data);
              } else {
                crudPaginationMX().total(res.total);
                this.cruddataMX.dataList = res.data;
              }
            })
            .finally(() => {
              this.cruddataMX.callbackAfterInit();
              this.cruddataMX.loading = false;
            });
        },
        deleteItem: (item) => {
          Vue.prototype
            .dialog({
              title: '警告',
              content: '确认是否删除该条目，删除后不可恢复！',
              showClose: true,
            })
            .then((res) => {
              if (res == 'ok') {
                this.crudActionMX().delete(item);
              }
            });
        },
        newItem(item, onOpen) {
          cruddataMX.editingIndex = -1;
          // cleanValidation();
          // Object.assign(cruddataMX.data, item);
          crudActionMX().addActionPrepare(crud, cruddataMX.data, onOpen);
        },
        editItem(item, onOpen) {
          // cruddataMX.editingIndex = cruddataMX.dataList.indexOf(item);
          // cruddataMX.data = Object.assign({}, crud.default);
          //todo 为避免前台经常报数据类型错误的提示，注释掉以下方法，编辑回显数据应在 onOpen方法内处理
          //Object.assign(cruddataMX.data, item);
          let fnOpen;
          if (onOpen) {
            fnOpen = onOpen;
          } else {
            fnOpen = {};
          }
          console.log(item)
          crudActionMX().editActionPrepare(crud, item, fnOpen);
        },
        saveItem() {
          cruddataMX.runAction();
        },
        closeEditingPage(crud) {
          crud.switch = false;
          cruddataMX.editingIndex = -1;
          cleanValidation();
          cruddataMX.data = crud.default;
        },
      };
    },
    common() {
      return {
        select: (v, data, op, labelInValue) => {
          v = labelInValue ? v.key : v;
          this.$set(data, op, v);
        },
        selectMultiple: (keyset, namearray, data, field) => {
          const value = [];
          namearray.forEach((name) => {
            value.push(
              keyset
                .filter((key) => key.label === name)
                .map((value) => value.key)
            );
          });
          this.$set(data, field, value.flat());
        },
      };
    },
    hasPermission(permissionList) {
      if (
        permissionList &&
        permissionList instanceof Array &&
        permissionList.length
      ) {
        const roles = this.permission;
        return roles.some((role) => permissionList.includes(role));
      } else {
        console.warn('该类型不是一个数组');
      }
    },
  },
};

export default mixin;
