import http from '../http';

const path = '/fds/sys/violation';
export default {
  getViolationList(data) {
    return http.get(path, data);
  },
  getSelectInit() {
    return http.get(path + '/selectInit');
  },
  getDetail(uuid) {
    return http.get(path + '/detail', uuid);
  },
  updateViolationStatus(data) {
    return http.get(path + '/updateViolationStatus', data);
  },
};
