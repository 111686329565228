import axios from './axios';
import apiUtil from '../utils/api';
import exception from '@/utils/exception';
import msg from '@/components/KAlert';
import lodash from 'lodash';
import httpConfig from '../config/http.config';

const throttleArr = [],
  throttleTimes = 2000;

const initCustom = (custom, url, type) => {
  const defaultCustom = {
    throttle: false,
    noProcess: false,
  };
  return {
    url: url,
    type: type,
    throttle: custom?.throttle ? custom.throttle : defaultCustom.throttle,
    noProcess: custom?.noProcess ? custom.noProcess : defaultCustom.noProcess,
  };
};

let instance = (axiosTh, custom, data) => {
  if (custom.throttle) {
    const throttleMap = `${custom.type}-${custom.url}`;
    if (throttleArr.includes(throttleMap)) {
      return new Promise((resolve, reject) => {
        // exception.toastError(`请求节流，您点击的太快啦`, {all:true});
        msg.warning('请求节流，您点击的太快啦');
        reject(`请求节流，您点击的太快啦`);
      });
    }
    throttleArr.push(throttleMap);
    setTimeout(() => {
      lodash.remove(throttleArr, (p) => {
        return p === throttleMap;
      });
    }, throttleTimes);
  }

  if (custom?.noProcess) {
    return axiosTh;
  } else {
    return new Promise((resolve, reject) => {
      axiosTh
        .then((response) => {
          const res = response?.data || response;
          apiUtil
            .resultHandle(res, custom)
            .then((res) => {
              res.origin = data;
              resolve(res);
            })
            .catch((e) => {
              e.origin = data;
              reject(e);
            });
        })
        .catch((error) => {
          console.log(error);
          // error.origin = data;
          if (error && error.status && error.status !== 403) {
            exception.toastError(error);
          }

          reject(error);
        });
    });
  }
};

export default {
  get(url, data, headers, custom, responseType) {
    let options = {};

    options.params = data || {};
    if (headers) {
      options.headers = headers;
    }
    if (responseType) {
      options.responseType = responseType;
    }
    url = httpConfig.url + url;
    custom = initCustom(custom, url, 'get');
    return instance(axios().get(url, options), custom, data);
  },
  post(url, data, headers, custom, callback, timeout = 10000) {
    let options = {};
    if (headers) {
      options.headers = headers;
      options.timeout = timeout;
    }
    if (callback) {
      options.onUploadProgress = callback;
    }
    url = httpConfig.url + url;
    custom = initCustom(custom, url, 'post');
    return instance(axios().post(url, data, options), custom, data);
  },
  put(url, data, headers, custom) {
    let options = {};
    if (headers) {
      options.headers = headers;
    }
    url = httpConfig.url + url;
    custom = initCustom(custom, url, 'put');
    return instance(axios().put(url, data, options), custom, data);
  },
  delete(url, data, headers, custom) {
    let options = {};
    options.params = data || {};
    if (headers) {
      options.headers = headers;
    }
    url = httpConfig.url + url;
    custom = initCustom(custom, url, 'delete');
    return instance(axios().delete(url, options), custom, data);
  },
};
