<template>
  <div id="ruanjian" class="d-flex" :style="cssVars">
    <div class="mr-2 text-right input-label">{{ label }}:</div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      class="menu_date"
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <div id="ruanjian">
          <v-text-field
            v-model="Times"
            dense
            outlined
            label=""
            readonly
            :placeholder="placeholder"
            clearable
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </div>
      </template>

      <v-time-picker
        v-if="menu"
        :value="timeRange[0]"
        :max="entTime"
        :min="min"
        class="mt-4"
        format="24hr"
        @input="dateChange($event, 0)"
      >
      </v-time-picker>
      <v-time-picker
        v-if="menu"
        :value="timeRange[1]"
        :max="startTime"
        class="mt-4"
        format="24hr"
        @input="dateChange($event, 1)"
      >
      </v-time-picker>
      <div class="but">
        <div></div>
        <v-btn
          text
          color="primary"
          @click="ondate(tempTimeRange[0], tempTimeRange[1])"
        >
          确认
        </v-btn>
      </div>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: 'KDateRangeSelect',
  model: {
    prop: 'timeRange',
    event: 'change',
  },
  props: {
    // valueStart: { type: String, default: '' },
    // valueEnt: { type: String, default: '' },
    text: { type: String, default: '' },
    timeRange: { type: Array, default: () => ['', ''] },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    inputWidth: { type: String, default: '200px' },
    rules: { type: Array, default: () => [] },
    maxLength: { type: String, default: '20' },
    allowedHours: { type: [Array, Number, Function], default: () => [] },
    allowedminutes: { type: [Array, Number, Function], default: () => [] },
    min: { type: String, default: '' },
  },
  data() {
    return {
      Times: null,
      startTime: null,
      entTime: null,
      menu: false,
      tempTimeRange: [],
    };
  },
  computed: {
    defaultPlaceholder() {
      return this.placeholder === '' ? '请选择' + this.label : this.placeholder;
    },

    cssVars() {
      return {
        '--labelWidth': this.labelWidth,
        '--inputWidth': this.inputWidth,
      };
    },
  },
  watch: {
    timeRange(v) {
      if (v?.length) {
        this.Times = `${this.timeRange[0] || '--'}-${
          this.timeRange[1] || '--'
        }`;
      }
    },
  },
  methods: {
    dateChange(e, index) {
      this.tempTimeRange[index] = e;
      if (this.tempTimeRange[0] > this.tempTimeRange[1]) {
        this.$alert.warning('开始时间大于结束时间');
        return;
      }
      // this.Times = `${this.tempTimeRange[0] || '--'}-${
      //   this.tempTimeRange[1] || '--'
      // }`;
      // this.$emit('change', this.tempTimeRange);
    },
    ondate(a, b) {
      // this.menu = false;
      if (a === null || b === null) {
        this.$alert.warning('时间格式不规范');
      } else {
        // eslint-disable-next-line no-debugger
        debugger;
        if (a > b) {
          this.$alert.warning('开始时间大于结束时间');
          return;
        }
        this.Times = a + '-' + b;
        this.menu = false;
        this.$emit('change', this.tempTimeRange);
      }
    },
  },
};
</script>
<style>
#ruanjian .v-picker {
  width: 50% !important;
}
#ruanjian .d-flex .align-center {
  width: 300px !important;
}
#ruanjian .input-label {
  width: 15% !important;
}
#ruanjian .v-input {
  width: 300px;
}
#ruanjian {
  align-items: center;
}
</style>
<style lang="scss" scoped>
.but {
  background: #fff;
  display: flex;
  justify-content: space-between;
}
.input-field {
  width: var(--inputWidth);
}
.input-label {
  width: var(--labelWidth);
}
//让错误浮动的显示在入力框下面，并不占用页面空间。
::v-deep .v-text-field__details {
  position: absolute;
  top: 3em;
}
.menu_date {
  display: flex;
  top: 110px !important;
}
</style>
