import api from '../../api';
import encryption from '../../utils/encryption';
import lodash from 'lodash';
import timer from '../../utils/data/timer';
import httpConfig from '../../config/http.config';
import router from '../../router';
import KAlert from '@/components/KAlert';

// TODO COPY FROM OLD OBJECT??? QingShuang
const assemblyRoute = (route) => {
  const reRoute = {
    uuid: route.uuid,
    path: route.path,
    name: route.sign,
    meta: {
      title: route.name,
      icon: route.icon,
    },
    parentUuid: route.parentUuid,
    subs: [],
    collapse: false,
  };
  reRoute.component = lodash.isEmpty(route.parentUuid)
    ? { render: (e) => e('router-view') }
    : () => import(`../../views${route.component}`);

  return reRoute;
};

const findChildrenRoute = (route, routes) => {
  return lodash.unionBy(
    route.children,
    routes.filter((p) => p.parentUuid === route.uuid),
    'uuid'
  );
};

const getPwd = (data) => {
  if (data.iv.useLastPwd) {
    return encryption.AES.decrypt(data.password, data.iv.iv);
  } else {
    return data.password;
  }
};

const defaultAccount = {
  account: '',
  userName: '',
  tel: '',
  avatar: '',
  status: '',
  token: '',
  expire: -1,
  uuid: '',
  logicPersonInfo: {},
  roleVos: [],
  createdTime: '',
  permission: [],
};

export default {
  state: {
    account: defaultAccount,
    isLogin: false,
    routes: [],
  },
  actions: {
    login(context, data) {
      let ms = new Date().getTime();
      const password = getPwd(data);
      const params = {
        account: data.account,
        password: encryption.AES.encrypt(password, ms),
        timestamp: ms,
      };
      return new Promise((resolve, reject) => {
        api.system.account
          .login(params)
          .then((res) => {
            if (Array.isArray(res.data) && res.data.length) {
              const { organizationId } = res.data[0];
              if (organizationId)
                context.commit(
                  'screenStore/SET_subOrganizationId',
                  organizationId
                );
            }
            if (
              res.data &&
              res.data.length > 0 &&
              res.data[0].roleVos &&
              res.data[0].roleVos.length > 0 &&
              res.data[0].roleVos[0].routeVos &&
              res.data[0].roleVos[0].routeVos.length > 0
            ) {
              context.commit('SET_ACCOUNT', res.data[0]);
              context.commit('LOGIN');
              this.dispatch('getMsg');
              // context.commit('SET_PERMISSION', res.data[0].permission);
              // context.commit('SAVE_PWD', {
              //   password: password,
              //   iv: res.data[0].createdTime,
              // });
              // const heartbeatTimer = timer.getTimer(
              //   httpConfig.httpTimer.heartbeat.name
              // );
              // heartbeatTimer.handle();
              // timer.restartTimer(heartbeatTimer);
              // const messageTimer = timer.getTimer(
              //   httpConfig.httpTimer.messageListen.name
              // );
              // timer.restartTimer(messageTimer);
              router.push({ name: 'home', params: {} });
              resolve(res);
            } else {
              reject('没有权限访问');
              KAlert.error('您没有权限访问，请联系管理员');
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout(context, params) {
      context.commit('LOGOUT');
      const heartbeatTimer = timer.getTimer(
        httpConfig.httpTimer.heartbeat.name
      );
      timer.stopTimer(heartbeatTimer);
      const messageTimer = timer.getTimer(
        httpConfig.httpTimer.messageListen.name
      );
      timer.stopTimer(messageTimer);
      router.push({ name: 'login' });
      router.resetRoute();
      api.system.account.logout(params).finally(() => {
        context.commit('LOGOUT_TOKEN');
      });
    },
    logoutWithoutResetRoute(context, params) {
      context.commit('LOGOUT');
      const heartbeatTimer = timer.getTimer(
        httpConfig.httpTimer.heartbeat.name
      );
      timer.stopTimer(heartbeatTimer);
      const messageTimer = timer.getTimer(
        httpConfig.httpTimer.messageListen.name
      );
      timer.stopTimer(messageTimer);
      api.system.account.logout(params).finally(() => {
        context.commit('LOGOUT_TOKEN');
      });
    },
    getRoutes(context) {
      return new Promise((resolve, reject) => {
        try {
          context.commit('SET_ROUTES');
          resolve('success');
        } catch (e) {
          reject(e);
        }
      });
    },
    // keepPwd(context, data) {
    //   context.commit('KEEP_PWD', data);
    // },
  },
  mutations: {
    LOGIN(state) {
      state.isLogin = true;
    },
    LOGOUT(state) {
      state.isLogin = false;
      let lastAccount = lodash.cloneDeep(state.account);
      state.account = lodash.cloneDeep(defaultAccount);
      state.routes = [];
      state.account.token = lastAccount.token;
      state.account.account = lastAccount.account;
      localStorage.clear();
      sessionStorage.clear();
      
    },
    LOGOUT_TOKEN(state) {
      state.account.token = '';
    },
    SET_ACCOUNT(state, payload) {
      for (let i in state.account) {
        if (payload[i] != undefined) {
          state.account[i] = payload[i];
          state.account.token = 'Bearer ' + payload.accessToken;
        }
      }
    },
    SET_ROUTES(state) {
      const roleVos = lodash.cloneDeep(state.account.roleVos);
      const rolesRouteVos = roleVos.map((p) => p.routeVos);
      let routesVos = [],
        parentRoutes = [],
        routes = [];
      rolesRouteVos.forEach((p) => {
        routesVos = lodash.unionBy(routesVos, p, 'uuid');
      });
      routesVos.forEach((p) => {
        if (lodash.isEmpty(p.uuid)) {
          return false;
        }
        let route = assemblyRoute(p);
        if (lodash.isEmpty(p.parentUuid)) {
          parentRoutes.push(route);
        } else {
          routes.push(route);
        }
      });
      parentRoutes.forEach((p) => {
        p.subs = findChildrenRoute(p, routes);
        state.routes.push(p);
      });
    },
    // SET_PERMISSION(state, list) {
    //   // eslint-disable-next-line no-debugger
    //   debugger;
    //   const permissionList = lodash.cloneDeep(state.account.permissionList);
    //   state.permissionList = permissionList;
    // },
  },
};
