<template>
  <div class="d-flex align-center my-3" :style="cssVars">
    <div v-if="label" class="mr-2 text-right input-label">
      <span v-if="rules && rules.length" class="error--text">*</span
      >{{ label }}:
    </div>
    <template v-if="autoComplete">
      <v-autocomplete
        :value="value"
        :loading="loading"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        no-data-text="暂无更多数据！"
        :search-input.sync="searchInputSyncValue"
        outlined
        dense
        flat
        :multiple="multiple"
        :clearable="clearable"
        :chips="chips"
        deletable-chips
        :placeholder="defaultPlaceholder"
        solo
        class="input-field"
        :rules="rules"
        @change="selectChange"
      />
    </template>
    <template v-else>
      <v-select
        :value="value"
        dense
        outlined
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        label=""
        :loading="loading"
        :disabled="disabled"
        :placeholder="disabled ? '无数据' : defaultPlaceholder"
        :multiple="multiple"
        :chips="chips"
        deletable-chips
        :clearable="clearable"
        class="input-field"
        :rules="rules"
        @change="selectChange"
      >
        <!--        <template #selection="data">-->
        <!--          <slot name="selection" v-bind="data"></slot>-->
        <!--        </template>-->
      </v-select>
    </template>
  </div>
</template>
<script>
export default {
  name: 'KSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Array, Number],
      default: () => null,
    },
    items: { type: Array, default: () => [] },
    itemText: { type: [String, Array, Function], default: () => 'text' },
    itemValue: { type: [String, Array, Function], default: () => 'value' },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    inputWidth: { type: String, default: '200px' },
    rules: { type: Array, default: () => [] },
    maxLength: { type: String, default: '20' },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    chips: { type: Boolean, default: false },
    autoComplete: { type: Boolean, default: false },
    searchInputSync: { type: String, default: '' },
    clearable: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      searchInputSyncValue: '',
    };
  },
  computed: {
    defaultPlaceholder() {
      return this.placeholder === '' ? '请选择' + this.label : this.placeholder;
    },
    cssVars() {
      return {
        '--labelWidth': this.labelWidth,
        '--inputWidth': this.inputWidth,
      };
    },
  },
  watch: {
    value(v) {
      this.$emit('change', v);
    },
  },
  mounted() {
    this.searchInputSyncValue = this.searchInputSync;
    if (this.value) {
      this.$emit('change', this.value);
    }
  },
  methods: {
    selectChange(e) {
      this.$emit('change', e);
    },
  },
};
</script>
<style lang="scss" scoped>
.input-field {
  width: var(--inputWidth);
}
.input-label {
  width: var(--labelWidth);
}
//让错误浮动的显示在入力框下面，并不占用页面空间。
::v-deep .v-text-field__details {
  position: absolute;
  top: 3em;
}
</style>
