import http from '../http';

const path = '/fds/sys/hardware';
export default {
  getDevHardwareCamera(data) {
    return http.post(path + '/camera/list', data);
  },
  getProtocolList(data) {
    return http.get(path + '/protocol/list', data);
  },
  addCamera(data) {
    return http.post(path + '/camera/add', data);
  },
  editCamera(data) {
    return http.post(path + '/camera/edit', data);
  },
  deleteCamera(data) {
    return http.delete(path + '/camera/delete', data);
  },
  getDevHardwareAnalysisServer(data) {
    return http.post(path + '/psm-page', data);
  },
  addAnalysisServer(data) {
    return http.post(path + '/analysis-server/add', data);
  },
  editAnalysisServer(data) {
    return http.post(path + '/analysis-server/edit', data);
  },
  deleteAnalysisServer(data) {
    return http.delete(path + '/analysis-server/delete', data);
  },
  getOrganizationList(data) {
    return http.get(path + '/organization/list', data);
  },
  getOrganizationTypeList(data) {
    return http.get(path + '/organization-type/list', data);
  },
};
