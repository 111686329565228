import http from '../http';

const path = '/fds/api/mpc';

export default {
  getMPC(data) {
    return http.get(path, data);
  },
  addMPC(data) {
    return http.post(path, data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  editMPC(data) {
    return http.put(path, data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  deleteMPC(data) {
    return http.delete(path, data);
  },
  getModule() {
    return http.get(path + '/getModule');
  },
  getAnalysisItems(moduleUuid) {
    return http.get(path + '/getAnalysisItems', moduleUuid);
  },
  getDetail() {
    return http.get(path + '/getDetail');
  },
};
