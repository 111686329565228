import http from '../http';

const path = '/fds/api';
export default {
  getOperationList(data) {
    return http.post(path + '/olc/page', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
};
