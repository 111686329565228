<template>
  <div>
    <v-dialog v-model="visible" :max-width="maxwidth" persistent>
      <v-card class="pb-4">
        <v-card-title>
          <span>{{ title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('close')">
            <v-icon color="primary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <slot name="dialogContent"></slot>
        </v-card-text>

        <v-card-actions v-if="actionshow">
          <v-spacer></v-spacer>
          <k-cancel-button
            v-if="text === '取消'"
            :text="text"
            class="mr-1"
            @click="$emit('close')"
          />
          <k-cancel-button
            v-else
            :text="text"
            class="mr-1"
            @click="$emit('revoke')"
          />
          <k-primary-button
            :loading="resLoading"
            text="保存"
            icon="mdi-check"
            @click="$emit('save')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'CrudDialog',
  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: '' },
    maxwidth: { type: String, default: '500px' },
    actionshow: { type: Boolean, default: true },
    text: { type: String, default: '取消' },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapGetters({ resLoading: 'resLoading' }),
  },
  watch: {
    value() {
      this.visible = this.value;
    },
  },
};
</script>
<style lang="scss" scoped></style>
