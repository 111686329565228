import axios from 'axios';
import qs from 'qs';
// import lodash from 'lodash'
import store from '../store';
import router from '@/router';
import httpConfig from '../config/http.config';
// import router from '../router';
// import timer from '../utils/data/timer';
import Vue from 'vue';
import Moment from 'moment';
let errTimes = 0;
let service = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    Accept: 'application/json, text/plain, */*',
  },
  timeout: 10000,
});

const checkParams = (params) => {
  if (params && !params.timestamp) {
    params.timestamp = new Date().getTime();
  }
  for (let i in params) {
    if (params[i] instanceof Moment) {
      params[i] = params[i].valueOf();
    }
  }
};

service.interceptors.request.use(
  (config) => {
    if (config.method === 'get') {
      checkParams(config.params);
      config.params = qs.stringify(config.params);
      config.url = config.url + '?' + config.params;
      config.params = {};
    }
    if (config.method === 'post') {
      if (config.headers['Content-Type'] === 'application/json;charset=UTF-8') {
        let json = config.data;
        if (!json.timestamp) {
          json.timestamp = new Date().getTime();
        }

        config.data = JSON.stringify(json);
      } else if (config.headers['Content-Type'] === 'multipart/form-data') {
        console.log('');
      } else {
        checkParams(config.data);
        config.data = qs.stringify(config.data);
      }
    }
    if (config.method === 'put') {
      if (config.headers['Content-Type'] === 'application/json;charset=UTF-8') {
        let json = config.data;
        if (!json.timestamp) {
          json.timestamp = new Date().getTime();
        }
        config.data = JSON.stringify(json);
      } else {
        checkParams(config.data);
        config.data = qs.stringify(config.data);
      }
    }
    if (config.url.indexOf(httpConfig.url) > -1) {
      // config.headers[httpConfig.header.ACCESS_ORIGIN] =
      //   router.currentRoute.name;
      config.headers[httpConfig.header.ACCESS_TOKEN] = store.getters.token;
      // if (store.getters.isLogin && config.url.indexOf('heartbeat') == -1) {
      //   if (process.env.NODE_ENV === 'production') {
      //     timer.restartTimerByName(httpConfig.httpTimer.heartbeat.name);
      //   }
      // }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // let msg = '';
    if (error.response) {
      // msg = error.response?.status + ':' + error.response?.statusText;/
      switch (error.response.status) {
        case 403:
          errTimes++;
          if (errTimes === 1) {
            Vue.prototype
              .dialog({
                title: '提示',
                content: '登录过期，请重新登录！',
                showClose: false,
              })
              .then((res) => {
                if (res == 'ok') {
                  store.commit('LOGOUT');
                  router.replace({ path: '/login' });
                  errTimes = 0;
                }
              });
          }
          break;
      }
    } else {
      // msg = error;
    }
    // throw new Error(msg);
    return Promise.reject(error.response);
  }
);

export default function () {
  return service;
}
