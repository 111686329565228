<template>
  <v-btn color="primary" outlined dark @click="$emit('click')">
    <v-icon> {{ iconc }} </v-icon>
    {{ text }}
  </v-btn>
</template>
<script>
export default {
  name: 'KCancelButton',
  props: {
    text: { type: String, default: '' },
    icon: { type: String, default: '' },
  },
  computed: {
    iconc() {
      return this.icon === '' ? 'mdi-close' : this.icon;
    },
  },
};
</script>
<style lang="scss" scoped></style>
