/**
 * @author bbx
 */
export default {
  format: {
    normal: 'YYYY-MM-DD HH:mm:ss',
    onlyDate: 'YYYY-MM-DD',
    onlyMonth: 'YYYY-MM',
    onlyYear: 'YYYY',
    eraseHMS: 'YYYY-MM-DD 00:00:00',
    eraseHMSD: 'YYYY-MM-01 00:00:00',
    endDate: 'YYYY-MM-DD 23:59:59',
    week: 'dddd',
    times: 'x',
  },
};
