import http from '../http';

const path = '/fds/schedule/task/';
export default {
  getTaskGeneration(data) {
    return http.post(path + 'page', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  getDict(data) {
    return http.get(path + 'enum-dict', data);
  },
  changeStatus(data) {
    return http.post(path + 'status', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  taskType(data) {
    return http.get(path + 'task-type', data);
  },
  taskInfo(data) {
    return http.get(path + 'info', data);
  },
  addTaskGeneration(data) {
    return http.post(path + 'add', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  editTaskGeneration(data) {
    return http.post(path + 'update', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  deleteTaskGeneration(data) {
    return http.post(path + 'delete', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  getAnalysisItems() {
    return http.get(path + '/getAnalysisItems');
  },
  startTask(uuid) {
    return http.post(path + '/startTask', uuid);
  },
  pauseTask(uuid) {
    return http.post(path + '/pauseTask', uuid);
  },
};
