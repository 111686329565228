import http from '../http';

const path = '/upms/file';
export default {
  upload(data, callback) {
    return http.post(
      path,
      data,
      {
        'Content-Type': 'multipart/form-data',
      },
      {},
      callback
    );
  },

  getDomain() {
    return http.get(path);
  },
};
