<!--explain：;@author: cy; @version: 1.0; date: 2022/4/12;-->
<template>
  <div>
    <div v-if="uploadType === 'img'" class="d-flex justify-center">
      <div
        v-if="label"
        class="mr-2 text-right input-label"
        :style="{ width: labelWidth }"
      >
        <span v-if="rules && rules.length" class="error--text">*</span
        >{{ label }}:
      </div>
      <v-hover v-slot="{ hover }">
        <div
          class="upload-warp"
          :class="{
            'error-submit':
              $refs.file && $refs.file.validationState === 'error',
          }"
          :style="[upH, upW]"
        >
          <v-img
            v-if="value !== '' && value"
            :max-width="width"
            :max-height="height"
            :src="commonUrl + value"
          />
          <span v-else class="icon-plus" @click="uploadBtnAction">+</span>
          <v-overlay :value="upLoad" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-file-input
            v-if="!value"
            ref="file"
            style="display: none"
            :value="fileValue"
            class="upload-action"
            :rules="rules"
            :accept="accept"
            truncate-length="15"
            outlined
            solo
            full-width
            filled
            @change="uploadChange"
            @update:error="updateError"
          >
            <template #message="{ message }">
              <div class="err-msg">
                {{ message }}
              </div>
            </template>
          </v-file-input>
          <v-overlay v-if="hover && value !== '' && value" absolute>
            <div>
              <v-icon class="mr-2" color="primary" @click="preview = true"
                >mdi-eye</v-icon
              >
              <v-icon v-if="!disabled" color="error" @click="delImg"
                >mdi-delete</v-icon
              >
            </div>
          </v-overlay>
        </div>
      </v-hover>
      <v-overlay
        v-if="uploadType === 'img'"
        :value="preview"
        @click="preview = !preview"
      >
        <v-img :src="commonUrl + value"></v-img>
      </v-overlay>
    </div>
    <div v-if="uploadType === 'btn'">
      <!--文件上传-->
      <v-file-input
        ref="file"
        v-model="fileValue"
        :multiple="multiple"
        style="display: none"
        :accept="accept"
        @change="uploadChange"
      >
      </v-file-input>
      <v-btn
        :loading="upLoad"
        :color="btnType"
        :disabled="disabled"
        @click="uploadBtnAction"
      >
        <v-icon left>
          {{ btnIcon }}
        </v-icon>
        {{ uploadText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import httpConfig from '@/config/http.config';
export default {
  name: 'UpLoad',
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    value: { type: [String, Array, Object, Function], default: () => '' },
    width: { type: [String, Number], default: () => '160px' },
    height: { type: [String, Number], default: () => '160px' },
    uploadType: { type: String, default: () => 'img' },
    accept: { type: String, default: () => 'image/*' },
    multiple: { type: Boolean, default: () => false },
    rules: { type: Array, default: () => [] },
    label: { type: String, default: () => '' },
    labelWidth: { type: String, default: '100px' },
    uploadText: { type: String, default: '上传' },
    btnType: { type: String, default: 'primary' },
    btnIcon: { type: String, default: 'mdi-upload' },
    api: { type: [Object, Function], default: () => {}, required: true },
    appendUrlParams: { type: Object, default: () => {} }, //请求额外参数
    otherFormData: { type: Object, default: () => {} }, //文件额外参数
    disabled: { type: Boolean, default: () => false },
  },
  data() {
    return {
      isError: false,
      commonUrl: httpConfig.imgUrl,
      preview: false,
      upLoad: false,
      fileValue: null,
    };
  },
  computed: {
    upH() {
      const num = +this.height;
      if (typeof num === 'number') {
        return `height: ${num}px`;
      } else {
        return `height: ${num}`;
      }
    },
    upW() {
      const num = +this.width;
      if (typeof num === 'number') {
        return `width: ${num}px`;
      } else {
        return `width: ${num}`;
      }
    },
  },
  mounted() {
    if (this.value) {
      this.$emit('change', this.value);
    }
  },
  methods: {
    uploadBtnAction() {
      console.log('$refs.file---**', this.$refs.file);
      this.$refs.file.$refs.input.click();
    },
    async uploadChange(e) {
      let file;
      if (this.uploadType === 'btn') {
        file = this.fileValue;
      } else {
        file = e;
      }
      console.log('file----', file);
      const otherParams = this.appendUrlParams; //TODO 请求链接上的额外参数
      const otherFormData = this.otherFormData; //TODO formData的额外参数
      const formData = new FormData();
      formData.append('file', file);
      if (otherFormData && this.typeCheck(otherFormData, 'Object')) {
        Object.keys(otherFormData).forEach((key) => {
          formData.append(key, otherFormData[key]);
        });
      }
      let appendUrlParams = '';
      if (otherParams && this.typeCheck(otherParams, 'Object')) {
        try {
          appendUrlParams = `?${new URLSearchParams(
            Object.entries(otherParams)
          ).toString()}`;
        } catch {
          Object.keys(otherParams).forEach((key, index) => {
            if (index === 0) {
              appendUrlParams = appendUrlParams.concat(
                `?${key}=${otherParams[key]}`
              );
            } else {
              appendUrlParams = appendUrlParams.concat(
                `&${key}=${otherParams[key]}`
              );
            }
          });
        }
      }
      this.upLoad = true;
      await this.api(formData, appendUrlParams)
        .then(({ code, data, message }) => {
          if (code === 200 || code === 0) {
            this.fileValue = null;
            this.upLoad = false;
            this.$alert.success(message || '操作成功');
            // if (data.length) {
            //   if (data[0].fileNames ?? data[0].fileNames[0] ?? false) {
            //     data[0] = data[0].fileNames[0];
            //   }
            // }
            this.$emit('uploadSuccess');
            console.log('---***', data[0]);
            this.$emit('change', data?.[0]);
          }
        })
        .catch(() => {
          this.fileValue = null;
          this.upLoad = false;
        });
    },
    typeCheck(val, type) {
      return Object.prototype.toString.call(val) === `[object ${type}]`;
    },
    isEmptyObj(val) {
      const arrKeys = Object.getOwnPropertyNames(val);
      return arrKeys.length === 0;
    },
    delImg() {
      this.$emit('change', '');
    },
    updateError(err) {
      console.log('updateError---', err);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-warp {
  display: flex;
  position: relative;
  background-color: #f9fbff;
  border: 1px #2e71fe dashed;
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
  width: 160px;
  height: 160px;
  .icon-plus {
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 42px;
    color: #2e71fe;
  }
  .upload-action {
    position: absolute;
    cursor: pointer;
    //width: 100%;
    //left: 50%;
    //top: 50%;
    //transform: translate(-50%, -50%);
    z-index: 998;
    opacity: 1;
  }
}
.error-submit {
  border-color: red !important;
}
.err-msg {
  position: absolute;
  width: 100%;
  height: 100%;
}
::v-deep .v-text-field__details {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
</style>
