var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.uploadType === 'img')?_c('div',{staticClass:"d-flex justify-center"},[(_vm.label)?_c('div',{staticClass:"mr-2 text-right input-label",style:({ width: _vm.labelWidth })},[(_vm.rules && _vm.rules.length)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(_vm.label)+": ")]):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"upload-warp",class:{
          'error-submit':
            _vm.$refs.file && _vm.$refs.file.validationState === 'error',
        },style:([_vm.upH, _vm.upW])},[(_vm.value !== '' && _vm.value)?_c('v-img',{attrs:{"max-width":_vm.width,"max-height":_vm.height,"src":_vm.commonUrl + _vm.value}}):_c('span',{staticClass:"icon-plus",on:{"click":_vm.uploadBtnAction}},[_vm._v("+")]),_c('v-overlay',{attrs:{"value":_vm.upLoad,"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(!_vm.value)?_c('v-file-input',{ref:"file",staticClass:"upload-action",staticStyle:{"display":"none"},attrs:{"value":_vm.fileValue,"rules":_vm.rules,"accept":_vm.accept,"truncate-length":"15","outlined":"","solo":"","full-width":"","filled":""},on:{"change":_vm.uploadChange,"update:error":_vm.updateError},scopedSlots:_vm._u([{key:"message",fn:function(ref){
        var message = ref.message;
return [_c('div',{staticClass:"err-msg"},[_vm._v(" "+_vm._s(message)+" ")])]}}],null,true)}):_vm._e(),(hover && _vm.value !== '' && _vm.value)?_c('v-overlay',{attrs:{"absolute":""}},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.preview = true}}},[_vm._v("mdi-eye")]),(!_vm.disabled)?_c('v-icon',{attrs:{"color":"error"},on:{"click":_vm.delImg}},[_vm._v("mdi-delete")]):_vm._e()],1)]):_vm._e()],1)]}}],null,false,418936167)}),(_vm.uploadType === 'img')?_c('v-overlay',{attrs:{"value":_vm.preview},on:{"click":function($event){_vm.preview = !_vm.preview}}},[_c('v-img',{attrs:{"src":_vm.commonUrl + _vm.value}})],1):_vm._e()],1):_vm._e(),(_vm.uploadType === 'btn')?_c('div',[_c('v-file-input',{ref:"file",staticStyle:{"display":"none"},attrs:{"multiple":_vm.multiple,"accept":_vm.accept},on:{"change":_vm.uploadChange},model:{value:(_vm.fileValue),callback:function ($$v) {_vm.fileValue=$$v},expression:"fileValue"}}),_c('v-btn',{attrs:{"loading":_vm.upLoad,"color":_vm.btnType,"disabled":_vm.disabled},on:{"click":_vm.uploadBtnAction}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.btnIcon)+" ")]),_vm._v(" "+_vm._s(_vm.uploadText)+" ")],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }