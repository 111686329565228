<template>
  <v-app ref="app" style="background-color: #fafafa">
    <keep-alive>
      <router-view />
    </keep-alive>
  </v-app>
</template>

<script>
import { onFull } from '@/views/screen/config';
import { mapMutations } from 'vuex';
export default {
  name: 'App',
  watch: {
    $route: {
      deep: true,
      handler: function (to, from) {
        setTimeout(() => {
          if (
            from.name == 'home' &&
            ['home-screen', 'home-screen-sub'].includes(to.name)
          ) {
            this.$bus.$emit('screen-full');
          }
        }, 250);
      },
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onFull);
    document.addEventListener('fullscreenchange', this.fullscreenchange);
    this.$bus.$on('screen-full', (e) =>
      this.onFull(
        e && e.key
          ? e
          : {
              key: 'F11',
            }
      )
    );
  },
  destroyed() {
    window.removeEventListener('keydown', this.onFull);
    document.removeEventListener('fullscreenchange', this.fullscreenchange);
  },
  methods: {
    ...mapMutations(['screenStore/SET_isFull']),
    onFull(e) {
      if (e.key == 'F11') {
        if (e.preventDefault) {
          e.preventDefault();
          e.returnValue = false;
        }
        if (this.$refs.app) {
          onFull(this.$refs.app.$el);
        }
      }
    },
    fullscreenchange() {
      const isFull =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement;
      this['screenStore/SET_isFull'](Boolean(isFull));
    },
  },
};
</script>

<style lang="scss" scoped>
@import './assets/css/common.scss';
@import './assets/css/screen.scss';
@import './assets/font/font-awesome.css';
@import './assets/font/font.css';
::v-deep .amap-container{
  background-image: none !important;
}
</style>
