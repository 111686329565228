/*explain：;@author: cy; @version: 1.0; date: 2022/4/2;*/
import http from '../http';
const path = '/fds/analysis/monitor/';
export default {
  getTab(data) {
    return http.get(path + 'model-list', data);
  },
  getDict(data) {
    return http.get(path + 'enum-dict', data);
  },
  getInfo(data) {
    return http.get(path + 'info', data);
  },
  getTransaction(data) {
    return http.get(path + 'transaction-list', data);
  },
  update(data) {
    return http.post(path + 'update', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
};
