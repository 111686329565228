import uiUtils from '@/utils/ui';

/**
 * @author bbx
 */
export default {
  silentError: (error, vm, info) => {
    if (info.indexOf('Promise') > -1) {
      console.warn(error);
    } else {
      console.error(error);
    }
  },
  toastError: (msg) => {
    console.error(`error:${msg}`);
    uiUtils.toast({
      type: 500,
      message: msg,
    });
  },
  toastWarning: (msg) => {
    uiUtils.toast({
      type: 404,
      message: msg,
    });
  },
  toastInfo: (msg) => {
    uiUtils.toast({
      type: 200,
      message: msg,
    });
  },
};
