<!-- eslint-disable -->
<template>
  <v-container>
    <v-dialog
      v-model="visible"
      persistent
      :fullscreen="fullscreen"
      :max-width="maxWidth"
      :width="width"
      :hide-overlay="overlay"
    >
      <div class="overlay" />
      <v-card class="common-dialog">
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-card-text v-html="content" />
        <v-card-actions>
          <v-spacer />
          <v-btn v-if="showClose" color="primary" outlined @click="m_cancel">
            {{ cancelText }}
          </v-btn>
          <v-btn color="primary" @click="m_ok">
            {{ okText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  props: {
    title: { type: String, default: '错误' }, // 标题
    width: { type: String, default: 'auto' }, // 组件的宽度
    maxWidth: { type: String, default: '500px' },
    fullscreen: { type: Boolean, default: false }, // 是否为全屏
    showClose: { type: Boolean, default: false }, // 是否显示关闭按钮
    okText: { type: String, default: '确定' }, // 确定按钮文字
    cancelText: { type: String, default: '取消' }, // 取消按钮文字
    content: { type: String, default: '' }, // 主体内容，可以传字符串 还有 html内容
    overlay: { type: Boolean, default: false },
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {},
  methods: {
    m_cancel() {
      this.close();
      this.$emit('cancel');
    },
    m_ok() {
      this.close();
      this.$emit('ok');
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.common-dialog .v-card__text {
  font-size: 16px;
}

.common-dialog {
  padding-bottom: 10px;
}
</style>
