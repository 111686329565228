<!--explain：;@author: cy; @version: 1.0; date: 2022/4/15;-->
<template>
  <div class="d-flex align-center my-3" :style="cssVars">
    <div v-if="label" class="mr-2 text-right input-label">
      <span v-if="rules && rules.length" class="error--text">*</span
      >{{ label }}:
    </div>
    <v-combobox
      :value="value"
      :rules="rules"
      :items="items"
      label=""
      :multiple="multiple"
      small-chips
      outlined
      dense
      :item-value="itemValue"
      :item-text="itemText"
      :disabled="disabled"
      @change="selectChange"
    >
      <template v-if="$scopedSlots.selection" #selection="data">
        <slot name="selection" v-bind="data"></slot>
      </template>
      <template #no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> 暂无更多数据... </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #item="{ item }">
        <div>{{ item[itemText] }}</div>
      </template>
    </v-combobox>
  </div>
</template>

<script>
export default {
  name: 'RzCombobox',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Array, null],
      default: () => [],
    },
    items: { type: Array, default: () => [] },
    itemText: { type: [String, Array, Function], default: () => 'text' },
    itemValue: { type: [String, Array, Function], default: () => 'value' },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    inputWidth: { type: String, default: '280px' },
    rules: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: true },
  },
  data() {
    return {};
  },
  computed: {
    defaultPlaceholder() {
      return this.placeholder === '' ? '请选择' + this.label : this.placeholder;
    },
    cssVars() {
      return {
        '--labelWidth': this.labelWidth,
        '--inputWidth': this.inputWidth,
      };
    },
  },
  mounted() {
    console.log(this.$slots);
    console.log(this.$scopedSlots);
    if (this.value) {
      this.$emit('change', this.value);
    }
  },
  methods: {
    selectChange(e) {
      this.$emit('change', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field {
  width: var(--inputWidth);
}
.input-label {
  width: var(--labelWidth);
}
//让错误浮动的显示在入力框下面，并不占用页面空间。
::v-deep .v-text-field__details {
  position: absolute;
  top: 3em;
}
</style>
