<template>
  <div class="d-flex align-center my-3" :style="cssVars">
    <div v-if="label" class="mr-2 text-right input-label">
      <span v-if="rules.length > 0" style="color: red">*</span>{{ label }}:
    </div>
    <v-text-field
      :value="value"
      v-bind="otherAttrs"
      dense
      outlined
      label=""
      :maxlength="maxLength"
      :clearable="clearable"
      :rules="rules"
      :placeholder="disabled ? '' : defaultPlaceholder"
      :type="type"
      :disabled="disabled"
      class="input-field"
      v-on="inputListeners"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  name: 'KTextInput',
  props: {
    value: { type: [String, Number], default: '' },
    text: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    inputWidth: { type: String, default: '200px' },
    rules: { type: Array, default: () => [] },
    maxLength: { type: String, default: '20' },
    clearable: { type: Boolean, default: true },
    type: { type: String, default: 'text' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      emptyArray: [],
    };
  },
  computed: {
    inputListeners: function () {
      let vm = this;
      return Object.assign(
        {},
        // 我们从父级添加所有的监听器
        this.$listeners,
        // 然后我们添加自定义监听器，
        // 或覆写一些监听器的行为
        {
          // 这里确保组件配合 `v-model` 的工作
          input: function (e) {
            vm.$emit('input', e);
          },
        }
      );
    },
    otherAttrs() {
      const props = this.$props;
      const attrs = this.$attrs;
      return { ...props, ...attrs };
    },
    defaultPlaceholder() {
      return this.placeholder === '' ? '请输入' + this.label : this.placeholder;
    },
    cssVars() {
      return {
        '--labelWidth': this.labelWidth,
        '--inputWidth': this.inputWidth,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.input-field {
  width: var(--inputWidth);
}
.input-label {
  width: var(--labelWidth);
}
//让错误浮动的显示在入力框下面，并不占用页面空间。
::v-deep .v-text-field__details {
  position: absolute;
  top: 3em;
}
</style>
