const getters = {
  token: (state) => state.loginStore.account.token,
  tokenExpire: (state) => state.loginStore.account.expire + 10 * 1000,
  isLogin: (state) => state.loginStore.isLogin,
  permissions: (state) => state.loginStore.account.roleVos,
  permission: (state) => state.loginStore.account.permission,
  routes: (state) => state.loginStore.routes,
  resLoading: (state) => state.messageStore.resLoading,
};

export default getters;
