/* eslint-disable no-debugger */
import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import loginStore from './module/login-store.js';
import messageStore from './module/message-store.js';
import screenStore from './module/screen-store.js';
import persistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loginStore,
    messageStore,
    screenStore,
  },
  plugins: [
    persistedState({
      storage: sessionStorage,
      reducer(val) {
        console.debug(val, 'xxxxxxx');
        return {};
      },
    }),
    persistedState({
      storage: {
        getItem(key) {
          if (localStorage.getItem('rememberMe') == 'true') {
            return localStorage.getItem(key);
          } else {
            // return Cookies.get(key);
            return sessionStorage.getItem(key);
          }
        },
        setItem(key, value) {
          if (localStorage.getItem('rememberMe') == 'true') {
            localStorage.setItem(key, value);
          } else {
            // Cookies.set(key, value);
            sessionStorage.setItem(key, value);
          }
        },
        removeItem(key) {
          if (localStorage.getItem('rememberMe') == 'true') {
            localStorage.removeItem(key);
          } else {
            // Cookies.remove(key);
            sessionStorage.removeItem(key);
          }
        },
      },
      reducer(val) {
        return {
          loginStore: {
            account: val.loginStore.account,
            isLogin: val.loginStore.isLogin,
            uuid: val.loginStore.uuid,
          },
          // messageStore: {
          //   msgData: val.messageStore.msgList,
          //   unreadCount: val.messageStore.unreadCount,
          // },
          // permissionList: val.loginStore.permissionList,
        };
      },
    }),
  ],
  getters,
  mutations: {},
});
