/*explain：;@author: cy; @version: 1.0; date: 2022/3/24;*/
import input from './input/index.js';
import button from './button/index.js';
const modulesFile = require.context('./', false, /\.vue$/);

const modules = modulesFile.keys().reduce((module, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFile(modulePath);
  module[moduleName] = value.default;
  return module;
}, {});
const data = { ...input, ...button, ...modules };
export default (Vue) => {
  // // eslint-disable-next-line no-debugger
  // debugger;
  Object.keys(data).map((item) => {
    Vue.component(item, data[item]);
  });
};
