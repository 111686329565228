import api from '../../api/';
import uiUtil from '../../utils/ui';
import router from '@/router';

export default {
  state: {
    unreadCount: 0,
    msgList: [],
    resLoading: false,
  },
  actions: {
    getMessage(context, params) {
      console.debug(params);
      api.system.message.getMessageListen().then((res) => {
        context.commit('SET_UN_READ_COUNT', res.data[0].unreadCount);
        if (res.data[0].hasNew) {
          uiUtil.notification({
            message: res.data[0].newMessage.title,
            description: res.data[0].newMessage.content,
            btn: [
              {
                text: '去查看',
                click: () => {
                  router.push({ name: 'message', params: {} });
                },
                size: 'small',
              },
            ],
          });
        }
      });
    },
    getMsg(context) {
      api.system.account.getUserMsg().then(({ data, code, total }) => {
        if (code === 200 || code === 0) {
          context.commit('SET_UN_READ_COUNT', total);
          context.commit('SET_MSG_DATA', data);
        }
      });
    },
    readMsg(context, item) {
      api.mine.mine.getread({ uuid: item.uuid }).finally(() => {
        this.dispatch('getMsg');
      });
    },
  },
  mutations: {
    SET_UN_READ_COUNT(state, payload) {
      state.unreadCount = payload;
    },
    SET_MSG_DATA(state, payload) {
      state.msgList = payload;
    },
    SET_LOAD(state, payload) {
      state.resLoading = payload;
    },
  },
};
