import moment from 'moment';
export const pageTabs = [
  {
    label: '预警总览',
    value: 'earlyWarning',
    position: 'left',
  },
  {
    label: '现金督查',
    value: 'cashSupervision',
    position: 'left',
  },
  {
    label: '交易复检',
    value: 'transactionRecheck',
    position: 'left',
  },
  {
    label: '服务质检',
    value: 'serviceSupervision',
    position: 'right',
  },
  {
    label: '厅堂助手',
    value: 'hallAssistant',
    position: 'right',
  },
  {
    label: '安保助手',
    value: 'safeAssistant',
    position: 'right',
  },
];

export const refreshTime = 1000 * 30 * 1;
/**
 * 根据一个日期计算出其所在的一周，反正对应一周的相应日期
 * basisDate: 依据日期, 默认是当天
 */
export function calcWeekDate(basisDate = moment().format('YYYY-MM-DD')) {
  const weekDate = [];
  const howWeek = moment(basisDate).day(); // 获取到依据日期为星期几；0为星期日、6为星期6
  // 如果今天是周日，则单独处理，往前计算6天
  if (howWeek === 0) {
    let mixins = 0;
    while (mixins <= 6) {
      // 一次减1；即一次减6天，5天... => 获取到了这周的周一、周二、到今天
      weekDate.unshift(
        moment(basisDate).subtract(mixins, 'days').format('YYYY-MM-DD')
      );
      mixins++;
    }
    return weekDate;
  }

  let minusNum = 1,
    addNum = 1;
  // 用今天的日期对应的星期数去往前遍历
  while (minusNum <= howWeek) {
    // 一次减1；即一次减6天，5天... => 获取到了这周的周一、周二、到今天
    weekDate.push(
      moment(basisDate)
        .subtract(howWeek - minusNum, 'days')
        .format('YYYY-MM-DD')
    );
    minusNum++;
  }

  // 7 - howWeek 即今天后面要加的天数（一周是7天）
  while (addNum <= 7 - howWeek) {
    weekDate.push(moment(basisDate).add(addNum, 'days').format('YYYY-MM-DD'));
    addNum++;
  }
  return weekDate;
}
/**
 * 根据一个日期计算出其所在的一月，反正对应一月的相应日期
 * basisDate: 依据日期, 默认是当天
 * replenishWeek: 是否需要将该月第一天和最后一天所在的周补全（通日历类似），默认需要
 */
export function calcMonthDate(
  basisDate = moment().format('YYYY-MM-DD'),
  replenishWeek = false
) {
  let monthDate = [];
  const monthStart = moment(basisDate).startOf('month').format('YYYY-MM-DD'); // 该月第一天
  const monthEnd = moment(basisDate).endOf('month').format('YYYY-MM-DD'); // 该月最后一天

  monthDate.push(monthStart); // 先将当月第一天放到数组中
  let addNum = 1;
  while (monthDate[monthDate.length - 1] < monthEnd) {
    // 依次用当月第一天加，直到该月的最后一天
    monthDate.push(moment(monthStart).add(addNum, 'days').format('YYYY-MM-DD'));
    addNum++;
  }

  if (replenishWeek) {
    // 根据参数，补全该月第一天和最后一天所在的周
    // 调用方法，得出该月第一天和最后一天分别是周几 => 星期日为 0、星期六为 6
    const monthStartWeek = moment(monthDate[0]).day();
    const monthEndWeek = moment(monthDate[monthDate.length - 1]).day();
    let addEmpty = 1,
      minusNum = 1;

    /** 注意：我们默认的日历显示是 周一 => 周日 */

    let unshiftEnd = monthStartWeek; // 默认，当月第一天是周几，就往前加n-1天(如周五：往前加5-1天； 周日：往前加7-1天)
    if (monthStartWeek === 0) {
      unshiftEnd = 7;
    }

    while (minusNum < unshiftEnd) {
      // 如果是周一，则一天也不会加
      // 一次减1；即分别在第一天的基础上减一天、两天、三天....;然后放到数组的前面
      monthDate.unshift(
        moment(monthStart).subtract(minusNum, 'days').format('YYYY-MM-DD')
      );
      minusNum++;
    }

    if (monthEndWeek !== 0) {
      // 如果最后一天是周日，则不补齐剩余日期
      // 7 - monthEndWeek 即这个月最后一天后面要加的天数（一周是7天） =>> 往后把一周补全(最后以周六结束，周日在最前面)
      // while(addEmpty < (7 - monthEndWeek + 1)) {   // 7 - monthEndWeek + 1 : 周天在最后；7 - monthEndWeek：周天在最前
      while (addEmpty <= 7 - monthEndWeek) {
        //<= : 周天在最后；<：周天在最前
        monthDate.push(
          moment(monthEnd).add(addEmpty, 'days').format('YYYY-MM-DD')
        );
        addEmpty++;
      }
    }
  }
  return monthDate;
}

export function toNumber(value) {
  return isNaN(value * 1) ? 0 : value * 1;
}

export const momentWeek = {
  0: '日',
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
};

export function onFull(element) {
  element = element ?? document.documentElement;
  const isFull =
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement;
  if (isFull) {
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    else if (document.msExitFullscreen) document.msExitFullscreen();
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
  } else {
    if (element.requestFullscreen) element.requestFullscreen();
    else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
    else if (element.msRequestFullscreen) element.msRequestFullscreen();
    else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
    else if (element.webkitRequestFullScreen) element.webkitRequestFullScreen();
  }
  return !isFull;
}

export function getBrowserCore() {
  const userAgent = navigator.userAgent;
  return {
    trident: userAgent.indexOf('Trident') > -1, //IE内核
    presto: userAgent.indexOf('Presto') > -1, //opera内核
    webKit: userAgent.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
    gecko: userAgent.indexOf('Gecko') > -1 && userAgent.indexOf('KHTML') == -1, //火狐内核
  };
}

export function deepCopy(target) {
  if (
    !['[object Object]', '[object Array]'].indexOf(
      Object.prototype.toString.call(this)
    )
  )
    return target;
  const source = target.constructor === Array ? [] : {};
  for (const i in target) {
    source[i] = deepCopy(target[i]);
  }
  return source;
}

export function assiginDeep(target = {}, sources = {}) {
  if (typeof target != 'object' || typeof sources != 'object') return sources;
  sources = JSON.parse(JSON.stringify(sources));
  for (const key in sources) {
    if (target[key] != undefined)
      target[key] = assiginDeep(target[key], sources[key]);
    else target[key] = sources[key];
  }
  return target;
}

export function flatTree(params) {
  const treeFlatList = [];
  params = Object.assign(
    {
      children: [],
      itemKey: 'id',
      itemChildren: 'children',
      parentKey: 'parentId',
      parentVal: 0,
    },
    params
  );
  return (function group(params) {
    if (!Array.isArray(params.children)) {
      return [];
    }
    for (const i in params.children) {
      if (
        !params.children[i] ||
        typeof params.children[i] !== 'object' ||
        Object.values(params.children[i]).length === 0
      ) {
        continue;
      }
      const item = JSON.parse(JSON.stringify(params.children[i]));
      group({
        ...params,
        children: item[params.itemChildren],
        parentVal: item[params.itemKey],
      });
      delete item[params.itemChildren];
      treeFlatList.push({
        ...item,
        [params.parentKey]: params.parentVal,
      });
    }
    return treeFlatList.sort((a, b) =>
      a[params.itemKey].localeCompare(b[params.itemKey], 'zh')
    );
  })(params);
}

export function formatString(number) {
  return `${[null, undefined, ''].includes(number) ? 0 : number}`;
}
export function isImage(str) {
  if (typeof str != 'string') return false;
  str = str.substr(str.lastIndexOf('.') + 1);
  return (
    ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(
      str.toLowerCase()
    ) !== -1
  );
}
export function debounce(fn, wait, immediate) {
  let timer;
  return function () {
    if (timer) clearTimeout(timer);
    if (immediate) {
      // 如果已经执行过，不再执行
      const callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, wait);
      if (callNow) {
        fn.apply(this, arguments);
      }
    } else {
      timer = setTimeout(() => {
        fn.apply(this, arguments);
      }, wait);
    }
  };
}
