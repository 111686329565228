/*explain：;@author: cy; @version: 1.0; date: 2022/4/6;*/
const phone = (val) => /^(1)\d{10}$/.test(val) || '手机号码格式不正确！';
const phoneNew = (val) =>
  /^(?!-)(?!.*?-$)[0-9-]+$/.test(val) || '号码格式不正确！';
const IP = (val) =>
  /((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))/.test(
    val
  ) || 'IP地址不规范';
const Number = (val) => /^[0-9]*$/.test(val) || '只能为数字！';
const Email = (val) =>
  /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(val) ||
  '请输入正确的邮箱';
const IDCard = (val) => {
  if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(val)) {
    console.log('你输入的身份证长度或格式错误');
    return '你输入的身份证长度或格式错误';
  }
  //身份证城市
  let aCity = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古',
    21: '辽宁',
    22: '吉林',
    23: '黑龙江',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门',
    91: '国外',
  };
  if (!aCity[parseInt(val.substr(0, 2))]) {
    console.log('你的身份证地区非法');
    return '你的身份证地区非法';
  }
  // 出生日期验证
  let sBirthday = (
      val.substr(6, 4) +
      '-' +
      +val.substr(10, 2) + //TODO Number()会出错会转为true
      '-' +
      +val.substr(12, 2)
    ).replace(/-/g, '/'),
    d = new Date(sBirthday);
  if (
    sBirthday !==
    d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate()
  ) {
    console.log('身份证上的出生日期非法');
    return '身份证上的出生日期非法';
  }
  // 身份证号码校验
  let sum = 0,
    weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
    codes = '10X98765432';
  for (let i = 0; i < val.length - 1; i++) {
    sum += val[i] * weights[i];
  }
  let last = codes[sum % 11]; //计算出来的最后一位身份证号码
  if (val[val.length - 1] !== last) {
    console.log('你输入的身份证号非法');
    return '你输入的身份证号非法';
  }
  return true;
};
//重复验证
const repeatTest = (val, key, list) => {
  const includeValList = list.filter((item) => item[key] !== val);
  return list.length > 1 ? includeValList.length > 0 || `重复数值${val}` : true;
  // (
  //   includeValList.every((item) => item[key] === val) || `重复${key}${val}`
  // );
};
// 相同
const likeTest = (val, key, list, msg) => {
  const includeValList = list.filter((item) => item[key] === val);
  return includeValList.length > 0 || msg || '新密码不相同请确认！';
};
const lengthTest = (val, len = 10) => {
  return val.length >= len || `长度至少应为${len}位!`;
};
export default {
  phone,
  IP,
  Number,
  Email,
  IDCard,
  repeatTest,
  phoneNew,
  likeTest,
  lengthTest,
};
