<!-- eslint-disable vue/no-mutating-props */ -->
<template>
  <div
    v-if="show"
    class="lb-notification flex column"
    :style="{ 'margin-bottom': `${bottom}px` }"
  >
    <div class="message mg-b-min">{{ message }}</div>
    <div class="description mg-b-min"></div>
    <a-icon class="icon" type="close" @click="show = false"></a-icon>
    <div class="btn flex jc-fe al-ct">
      <a-button-group>
        <a-button
          v-for="(b, i) in btn"
          :key="i"
          :type="b.type || 'primary'"
          :size="b.size"
          @click="(show = false), b.click()"
        >
          {{ b.text }}
        </a-button>
      </a-button-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    message: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    btn: {
      type: Array,
      default: () => {
        return [];
      },
    },
    bottom: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.lb-notification {
  position: relative;
  width: 100%;
  & > .description {
    line-height: 1.5;
  }
  & > .icon {
    position: absolute;
  }
}
</style>
