<template>
  <div>
    <div
      v-if="showTop"
      class="d-flex flex-wrap mb-4 rounded-lg white py-5 elevation-2 justify-space-between align-center"
    >
      <slot name="condition"></slot>
    </div>
    <div>
      <slot name="resultlist"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // showTop: { type: Boolean, default: () => true },
  },
  computed: {
    showTop() {
      return this.$slots['condition'];
    },
  },
};
</script>
<style lang="scss" scoped></style>
