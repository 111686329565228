/*explain：;@author: cy; @version: 1.0; date: 2022/3/24;*/
/*explain：;@author: cy; @version: 1.0; date: 2022/3/24;*/
const modulesFile = require.context('./', false, /\.vue$/);

const modules = modulesFile.keys().reduce((module, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFile(modulePath);
  module[moduleName] = value.default;
  return module;
}, {});
export default modules;
