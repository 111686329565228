<template>
  <v-btn
    :color="type"
    :disabled="disabled"
    :loading="loading"
    :small="mall"
    :icon="isicon"
    @click="$emit('click')"
  >
    <v-icon> {{ icon }} </v-icon> {{ text }}
  </v-btn>
</template>
<script>
export default {
  name: 'KPrimaryButton',
  props: {
    text: { type: String, default: '' },
    type: { type: String, default: 'primary' },
    icon: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    mall: { type: Boolean, default: false },
    isicon: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped></style>
