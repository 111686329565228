import http from '../http';

const path = '/fds/api/task';
export default {
  getTaskList(data) {
    return http.get(path, data);
  },
  init(data) {
    return http.get(path + '/init', data);
  },
  updateTaskStatus(data) {
    return http.put(path + '/updateTaskStatus', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  getTaskDetail(data) {
    return http.get(path + '/getTaskDetail', data);
  },
};
