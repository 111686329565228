/*explain：;@author: cy; @version: 1.0; date: 2022/4/12;*/
import http from '@/api/http';
const path = '/fds/message/push/config/';
export default {
  page(data) {
    return http.post(path + 'page', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  add(data) {
    return http.post(path + 'add', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  update(data) {
    return http.post(path + 'update', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  info(data) {
    return http.get(path + 'info', data);
  },
  del(data) {
    return http.post(path + 'delete', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  moduleList(data) {
    return http.get(path + 'module-list', data);
  },
  moduleItems(data) {
    return http.get(path + 'module-items', data);
  },
  roleList(data) {
    return http.get('/upms/role/tree', data);
  },
  dict(data) {
    return http.get(path + 'enum-dict', data);
  },
};
