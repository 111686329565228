/**
 * @author bbx
 */
import system from './system';
import resources from './resources';
import dev from './dev';
import organization from './organization';
import messagePush from './message';
import device from './device';
import software from './software';
import taskManage from './taskManage';
import taskList from './taskList';
import mine from './mine';
import violation from './violation';
import file from './file/file';
import nodeAnalysis from './nodeAnalysis';
import globalAnalysisConfig from '@/api/globalAnalysisConfig';
import networkConfig from '@/api/networkAnalysisConfig';
import customer from '@/api/customer';
import msgConfig from '@/api/msgConfig';
export default {
  msgConfig,
  customer,
  software,
  system,
  resources,
  dev,
  organization,
  messagePush,
  device,
  taskManage,
  taskList,
  mine,
  violation,
  file,
  nodeAnalysis,
  globalAnalysisConfig,
  networkConfig,
};
