<template>
  <div class="d-flex align-center" :style="cssVars">
    <div v-if="label" class="mr-2 text-right input-label">{{ label }}:</div>
    <v-radio-group
      v-model="modelValue"
      mandatory
      row
      class="my-0 py-0"
      hide-details="auto"
      :disabled="disabled"
    >
      <v-radio
        v-for="item in items"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></v-radio>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  name: 'KSelect',
  props: {
    value: undefined,
    items: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    rules: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      modelValue: null,
    };
  },
  computed: {
    cssVars() {
      return {
        '--labelWidth': this.labelWidth,
        '--inputWidth': this.inputWidth,
      };
    },
  },
  watch: {
    value() {
      this.modelValue = this.value;
    },
    modelValue() {
      this.$emit('input', this.modelValue);
    },
  },
  mounted() {
    this.modelValue = this.value;
  },
};
</script>
<style lang="scss" scoped>
.input-field {
  width: var(--inputWidth);
}
.input-label {
  width: var(--labelWidth);
}
//让错误浮动的显示在入力框下面，并不占用页面空间。
::v-deep .v-text-field__details {
  position: absolute;
  top: 3em;
}
</style>
