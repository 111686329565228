<template>
  <div v-if="show" class="lb-toast-Container flexCol-ct-ct"></div>
</template>

<script>
export default {
  name: 'ToastContainer',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Number,
      default: 20,
    },
    width: {
      type: Number,
      default: 230,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.lb-toast-Container {
  z-index: 9999991;
  position: fixed;
  width: 100%;
  height: 300px;
  bottom: 10px;
  left: 10px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  font-size: 12px !important;
  font-family: SiYuan-Regular, serif;

  & > .lb-toast:last-child {
    margin-bottom: 0 !important;
  }
}
</style>
