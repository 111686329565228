import http from '../http';

const url = '/fds/res';
export default {
  viewRes(path) {
    return `${url}${path}`;
  },
  getRes(path) {
    return http.get(`${url}`, {}, { p: path }, { noProcess: true }, 'blob');
  },
  postImage() {
    return `${url}/image`;
  },
  postAttachment() {
    return `${url}/attachment`;
  },
};
