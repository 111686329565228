import http from '../http';

const path = '/upms/authority';
export default {
  getAuthority(data) {
    return http.get(path + '/tree', data);
  },
  editAuthority(data) {
    return http.post(path, data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  addAuthority(data) {
    return http.put(path, data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  deleteAuthority(data) {
    return http.delete(path, data);
  },
  getPermissionById(data) {
    return http.get(path + '/treeByRoleId', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  updateRoleAuthority(data) {
    return http.post(path + '/updateRoleAuthority', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
};
