/**
 * @description 删除字符串左右两端的半角空格
 * @param str
 * @returns string
 */
function trim(str) {
  if (str !== null && str !== '' && str !== undefined) {
    return str.replace(/(^ *)|( *$)/gi, '');
  } else {
    return str;
  }
}

function getType(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
}

export default {
  trim,
  getType,
};
