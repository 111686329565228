/**
 * @author bbx
 */
const getUrl = () => {
  let _url;
  switch (process.env.NODE_ENV) {
    case 'development':
      _url = 'http://self:9095';
      break;
    case 'production':
      _url = 'http://self:9095';
      break;
    default:
      _url = 'http://self:9095';
  }
  return _url.replace('self', window.location.hostname);
};
/*
 * @author cy
 * 全局的图片前置地址33
 * */
const getImgAndVideoUrl = () => {
  let _url;
  switch (process.env.NODE_ENV) {
    case 'development':
      _url = 'http://self:9000/bbxfds/';
      break;
    case 'production':
      _url = 'http://self:9000/bbxfds/';
      break;
    default:
      _url = 'http://self:9000/bbxfds/';
  }
  return _url.replace('self', window.location.hostname);
};
const businessISG = 'fds';
export default {
  businessISG,
  businessApi: `${getUrl()}/${businessISG}`,
  url: getUrl(),
  imgUrl: getImgAndVideoUrl(),
  responseMessage: {
    code: 0,
    msg: '',
    data: [],
    total: 0,
    timestamp: new Date().getTime(),
  },
  header: {
    ACCESS_TOKEN: 'Authorization',
    // ACCESS_ORIGIN: 'ACCESS_ORIGIN',
  },
  httpTimer: {
    heartbeat: {
      name: 'heartbeat',
      timeOut: 10 * 1000,
    },
    messageListen: {
      name: 'messageListen',
      timeOut: 10 * 1000,
    },
  },
};
