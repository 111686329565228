import http from '../http';

const path = '/fds/api/nodeAnalysis';
export default {
  getNodeAnalysis(uuid) {
    return http.post(path + '/getNodeAnalysis', uuid);
  },
  getPsmList(uuid) {
    return http.post(path + '/getPsmList', uuid);
  },
  getanalysisItemsList(data) {
    return http.post(path + '/getanalysisItemsList', data);
  },
};
