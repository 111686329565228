import http from '@/api/http';
import httpConfig from '@/config/http.config';
import { pageTabs } from '@/views/screen/config';
const businessISG = httpConfig.businessISG;
export default {
  namespaced: true,
  state: {
    isFull: false,
    isSubbranch: false,
    subOrganizationId: sessionStorage.getItem('subOrganizationId'),
    pageKey: pageTabs[0].value,
    organizationTree: [],
    commondict: {},
    analysisitem: {},
    globalQuery: {},
    warningTotal: [],
  },
  mutations: {
    SET_isFull(state, isFull) {
      state.isFull = isFull;
    },
    SET_isSubbranch(state, isSubbranch) {
      state.isSubbranch = isSubbranch;
      if (isSubbranch && state.pageKey != pageTabs[0].value) {
        state.pageKey = pageTabs[0].value;
      }
    },
    SET_subOrganizationId(state, subOrganizationId) {
      state.subOrganizationId = subOrganizationId;
      sessionStorage.setItem('subOrganizationId', subOrganizationId);
    },
    SET_pageKey(state, pageKey) {
      state.pageKey = pageKey;
    },
    SET_organizationTree(state, organizationTree) {
      state.organizationTree = organizationTree;
    },
    SET_commondict(state, commondict) {
      state.commondict = commondict;
    },
    SET_analysisitem(state, analysisitem) {
      state.analysisitem = analysisitem;
    },
    SET_globalQuery(state, globalQuery) {
      state.globalQuery = globalQuery;
    },
    SET_warningTotal(state, warningTotal) {
      state.warningTotal = warningTotal
      // let up = warningTotal.up
      // let down = warningTotal.down
      // if (state.warningTotal.up){
      //    up = JSON.parse(JSON.stringify(state.warningTotal.up))
      //   warningTotal.up.forEach(v=>{
      //     up.forEach((j,i)=>{
      //       if (v.code === j.code) up.splice(i,1)
      //     })
      //     up.push(v)
      //   })
      //
      // }
      // if (state.warningTotal.down){
      //    down = JSON.parse(JSON.stringify(state.warningTotal.down))
      //   warningTotal.down.forEach(v=>{
      //     down.forEach((j,i)=>{
      //       if (v.code === j.code) down.splice(i,1)
      //     })
      //     down.push(v)
      //   })
      //
      // }
      // state.warningTotal={
      //   up,down
      // }
    },
  },
  actions: {
    init({ dispatch }) {
      for (const action of ['getCommondict', 'getAnalysisitem']) {
        dispatch(action);
      }
      return dispatch('getOrganization');
    },
    async getOrganization({ commit }) {
      let organizationTree = [];
      try {
        const data = await http.get(`/${businessISG}/organization/tree`);
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        organizationTree = data.data;
      } catch (error) {
        console.log(error);
      }
      commit('SET_organizationTree', organizationTree);
      return Promise.resolve(organizationTree);
    },
    async getAnalysisitem({ commit }) {
      const data = await http.get(
        `/${businessISG}/monitor/home/analysisitem-map`
      );
      if (data.code == 200 && data.data) {
        commit(
          'SET_analysisitem',
          data.data.reduce((t, c) => ({ ...t, ...c }), {})
        );
      }
    },
    async getCommondict({ commit }) {
      const data = await http.get(
        `/${businessISG}/monitor/home/commondict-list`
      );
      if (data.code == 200 && data.data) {
        commit(
          'SET_commondict',
          data.data.reduce((t, c) => ({ ...t, ...c }), {})
        );
      }
    },
  },
};
