/*explain：;@author: cy; @version: 1.0; date: 2022/4/6;*/
import http from '../http';
const path = '/fds/analysis/config/';
export default {
  getPage(data) {
    return http.post(path + 'page', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  getDict(data) {
    return http.get(path + 'enum-dict', data);
  },
  getInfo(data) {
    return http.get(path + 'info', data);
  },
  getIntems(data) {
    return http.get(path + 'intems', data);
  },
  getOrgPsm(data) {
    return http.get(path + 'org-psm', data);
  },
  getOrgcounter(data) {
    return http.get(path + 'org-counter', data);
  },
  getOrgcamera(data) {
    return http.get(path + 'org-camera', data);
  },
  getCameraImg(data) {
    return http.get(path + 'camera-img', data);
  },
  getTransaction(data) {
    return http.get(path + 'transaction-list', data);
  },
  changeStatus(data) {
    return http.post(path + 'status', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  del(data) {
    return http.post(path + 'delete', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  add(data) {
    return http.post(path + 'add', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  update(data) {
    return http.post(path + 'update', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
};
