<template>
  <div class="d-flex align-center" :style="cssVars">
    <v-switch
      v-model="modelValue"
      inset
      :readonly="readonly"
      :label="label"
      :rules="rules"
      :false-value="falseValue"
      :true-value="trueValue"
      :disabled="disabled"
      :loading="loading"
      hide-details
      class="input-field pa-0 ma-0"
      @change="$emit('change')"
    />
  </div>
</template>
<script>
export default {
  name: 'KSwitch',
  props: {
    value: { type: [String, Number, Boolean], default: false },
    text: { type: String, default: '' },
    label: { type: String, default: '' },
    rules: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    falseValue: {
      type: [String, Number, Boolean, undefined],
      default: undefined,
    },
    trueValue: {
      type: [String, Number, Boolean, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      modelValue: null,
      emptyArray: [],
    };
  },
  computed: {
    defaultPlaceholder() {
      return this.placeholder === '' ? '请输入' + this.label : this.placeholder;
    },
    cssVars() {
      return {
        '--labelWidth': this.labelWidth,
        '--inputWidth': this.inputWidth,
      };
    },
  },
  watch: {
    value() {
      this.modelValue = this.value;
    },
    modelValue() {
      this.$emit('input', this.modelValue);
    },
  },
  mounted() {
    this.modelValue = this.value;
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-label {
  font-size: 14px;
}

::v-deep .theme--light.v-label {
  color: rgba(0, 0, 0, 0.87);
}

//让错误浮动的显示在入力框下面，并不占用页面空间。
::v-deep .v-text-field__details {
  position: absolute;
  top: 3em;
}
</style>
