import http from '../http';

const path = '/upms/role';
export default {
  getRole(data) {
    return http.post(path + '/pageList', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  getRoleGroup(data) {
    return http.get(path + '/tree', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  editRole(data) {
    return http.post(path, data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  addRole(data) {
    return http.put(path, data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  deleteRole(data) {
    return http.delete(path, data);
  },
  editRoleGroup(data) {
    return http.put(path + '/group', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  saveRoleMenus(data) {
    return http.post(path + '/menu', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  saveRoleAuthorities(data) {
    return http.post(path + '/authority', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
};
