import http from '../http';

const path = '/sys/msg';
export default {
  getMessage(data) {
    return http.get(path, data);
  },
  getMessageListen(data) {
    return http.get(path + '/listen', data);
  },
  messageRead(data) {
    return http.post(path + '/read', data);
  },
  messageReadAll(data) {
    return http.post(path + '/read/all', data);
  },
};
