/**
 * @author bbx
 */
import account from './account';
import message from './message';
import operation from './operation';
import role from './role';
import user from './user';
import msgLog from './msgLog';
export default {
  account,
  message,
  operation,
  user,
  role,
  msgLog,
};
