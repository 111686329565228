import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import zhHans from 'vuetify/lib/locale/zh-Hans';
Vuetify.config.silent = true;
Vue.use(Vuetify);
export default new Vuetify({
  lang: {
    locales: { zhHans },
    current: 'zh-Hans',
  },
  theme: {
    themes: {
      options: { customProperties: true },
      light: {
        blue: '#E0ECF9',
        bluegrey: '#233862',
        purple: '#8B68FF',
        primary: {
          base: '#2e71fe',
          lighten1: '#E0ECF9',
        },
        secondary: '#0dc5e1',
        accent: '#82B1FF',
        error: {
          base: '#F5194D',
          lighten1: '#FFEBF0',
        },
        info: '#ccc',
        success: '#0ecb8b',
        warning: {
          base: '#ff9b07',
          lighten1: '#FFEBCE',
        },
      },
      dark: {
        primary: '#2196F3',
        secondary: '#424242',
        accent: '#FF4081',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
    },
  },
});
