import Vue from 'vue';
import Dialog from '@/components/koi/KDialog';
import KAlert from '@/components/koi/KAlert';
import Vuetify from 'vuetify/lib';
import comUtils from './utils';

const holder = {
  alertvm: null,
  dialogvm: null,
};

const vms = {
  create(comp, obj) {
    // eslint-disable-next-line new-cap
    let vm = new comp();
    vm.$vuetify = new Vuetify().framework;
    vm = vm.$mount();
    if (obj) {
      for (const o in obj) {
        vm[o] = obj[o];
      }
    }
    document.body.appendChild(vm.$el);
    return vm;
  },
  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },
};

const MyDialog = Vue.extend(Dialog);
const MyAlert = Vue.extend(KAlert);
let speed = 0;
const funs = {
  dialog(obj) {
    // eslint-disable-next-line no-debugger
    // debugger;
    if (
      comUtils.getType(obj) === 'string' ||
      comUtils.getType(obj) === 'number' ||
      comUtils.getType(obj) === 'array'
    ) {
      obj = { content: obj };
    }

    let vm = null;
    if (holder.dialogvm) {
      vm = holder.dialogvm;
    } else {
      vm = vms.create(MyDialog);
      vm.id = 'dialog' + speed++;
      holder.dialogvm = vm;
    }
    const promise = new Promise((resolve) => {
      vm.$on('ok', () => {
        // 确定返回
        resolve('ok');
      });
      vm.$on('cancel', () => {
        // 取消返回
        resolve('cancel');
      });
    });
    Object.assign(vm, obj);
    vm.visible = true;
    return promise;
  },
  alert(obj) {
    if (
      comUtils.getType(obj) === 'string' ||
      comUtils.getType(obj) === 'number' ||
      comUtils.getType(obj) === 'array'
    ) {
      obj = { content: obj, uuid: vms.uuidv4() };
    }
    obj.uuid = vms.uuidv4();
    let vm = null;
    if (holder.alertvm) {
      vm = holder.alertvm;
    } else {
      vm = vms.create(MyAlert);
      holder.alertvm = vm;
    }

    const promise = new Promise((resolve) => {
      vm.$on('ok', () => {
        // 确定返回
        resolve('ok');
      });
      vm.$on('cancel', () => {
        // 取消返回
        resolve('cancel');
      });
    });
    Object.assign(vm, obj);
    vm.visible = true;
    return promise;
  },
};

Object.assign(Vue.prototype, {
  dialog: funs.dialog,
  kalert: funs.alert,
});

Vue.prototype.funs = funs;

export default funs;
