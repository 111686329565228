/**
 * @author bbx
 */

import menu from './menu';
import authority from './authority';
export default {
  menu,
  authority,
};
