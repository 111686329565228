<template>
  <v-alert
    v-model="visible"
    dense
    dismissible
    close-label="alert"
    :type="type"
    class="m-0 p-0 mx-auto"
    style="
      font-size: 14px;
      pointer-events: auto;
      position: fixed;
      left: 0;
      right: 0;
      z-index: 99999;
    "
    :style="{ top: verticalOffset }"
    min-width="300px"
    max-width="600px"
    transition="scale-transition"
    @input="clickClose"
  >
    {{ content }}
  </v-alert>
  <!--  </transition>    -->
  <!--    </v-container>-->
  <!--  </v-app>-->
</template>
<script>
export default {
  props: {
    uuid: { type: String, default: '' },
    color: { type: String, default: '' },
    showClose: { type: Boolean, default: false },
  },
  data() {
    return {
      verticalOffset: 0,
      messages: [],
      visible: true,
      timer: 0,
      duration: 3000,
      onClose: null,
      content: '',
      type: undefined,
      closed: false,
    };
  },
  watch: {
    closed(newVal) {
      if (newVal) {
        this.verticalOffset = '0px';
        this.visible = false;
        // this.destroyElement();
        this.$el.addEventListener('transitionend', this.destroyElement);
      }
    },
  },
  mounted() {
    if (this.duration > 0) {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.close();
        }
      }, this.duration);
    }
  },
  methods: {
    clickClose() {
      this.close();
    },
    destroyElement() {
      this.$el.removeEventListener('transitionend', this.destroyElement);
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },
    close() {
      this.closed = true;
      if (typeof this.onClose === 'function') {
        this.onClose();
      }
    },
    clearTimer() {
      clearTimeout(this.timer);
    },
    startTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close();
          }
        }, this.duration);
      }
    },
    m_cancel() {
      this.close();
      this.$emit('cancel');
    },
    m_ok() {
      this.close();
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  font-size: 14px;
  position: absolute;
  top: 0;
  padding: 0;
  width: 100%;
  z-index: 100;
  pointer-events: none;
}

.v-application {
  background: transparent;
}
</style>
