<template>
  <div class="d-flex align-center my-3" :style="cssVars">
    <div class="mr-2 text-right input-label">
      <span v-if="rules && rules.length" class="error--text">*</span
      >{{ label }}:
    </div>
    <v-menu
      v-model="show"
      no-data-text="暂无更多数据！"
      :offset-y="true"
      :bottom="true"
      :close-on-content-click="false"
      max-height="500px"
    >
      <template #activator="{ attrs, on }">
        <v-text-field
          v-model="treeName"
          label=""
          dense
          :readonly="!hasInputSearch"
          outlined
          hide-details="auto"
          clearable
          v-bind="attrs"
          :rules="rules"
          :disabled="disabled"
          class="input-field"
          :placeholder="disabled ? '' : defaultPlaceholder"
          @click:clear="onClear"
          @blur="blurName"
          v-on="on"
        />
      </template>
      <v-card>
        <v-treeview
          v-if="!hasCheckBox"
          :active="value"
          :search="hasInputSearch ? treeName : empty"
          :filter="filter"
          :item-key="treeKey"
          :item-text="treeLabel"
          :item-children="treeChildrenKey"
          selection-type="independent"
          transition
          activatable
          :open-on-click="openOnClick"
          :items="items"
          selected-color="primary"
          @update:active="activeItem"
        ></v-treeview>
        <v-treeview
          v-if="hasCheckBox"
          :value="value"
          :search="hasInputSearch ? treeName : empty"
          :filter="filter"
          :item-key="treeKey"
          :item-text="treeLabel"
          :item-children="treeChildrenKey"
          transition
          :open-on-click="openOnClick"
          :items="items"
          selectable
          selected-color="primary"
          @input="checkItem"
        ></v-treeview>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    rules: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    value: { type: [Array, null], default: () => [] },
    label: { type: String, default: '' },
    treeLabel: { type: String, default: 'name' }, //树形显示名称
    treeKey: { type: String, default: 'id' }, //树形唯一ID
    treeChildrenKey: { type: String, default: 'children' }, //树形子集Key
    placeholder: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    inputWidth: { type: String, default: '200px' },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    openOnClick: { type: Boolean, default: false }, //点选父级展开子项，父级不选
    hasCheckBox: { type: Boolean, default: false }, //是否多选框
    hasInputSearch: { type: Boolean, default: true }, //是否模糊搜索
  },
  data() {
    return {
      empty: '',
      activeVal: [],
      show: false,
      selection: [],
      checkName: [],
      tree: [],
      treeName: null,
      treeData: [],
    };
  },
  computed: {
    filter() {
      return (item, search, textKey) => item[textKey].indexOf(search) > -1;
    },
    defaultPlaceholder() {
      return this.placeholder === '' ? '请选择' + this.label : this.placeholder;
    },
    cssVars() {
      return {
        '--labelWidth': this.labelWidth,
        '--inputWidth': this.inputWidth,
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) {
          this.selection = v;
          this.checkName = [];
          this.showName(this.items);
        }
      },
    },
  },
  mounted() {
    if (this.value) this.$emit('change', this.value);
    // this.selection = this.value;
    // this.checkName = [];
    // this.showName(this.items);
  },
  methods: {
    blurName() {
      // if (!this.selection.length) {
      //   this.treeName = null;
      // }
    },
    showName(data) {
      console.log('treeKey---', this.treeKey);
      const treeData = this.selection || [];
      const label = this.treeLabel;
      const value = this.treeKey;
      const children = this.treeChildrenKey;
      data.map((item) => {
        if (treeData.includes(item[value])) {
          if (!this.checkName.includes(item[label])) {
            this.checkName.push(item[label]);
          }
        } else if (item[children]?.length) {
          this.showName(item[children]);
        }
      });
      this.treeName = this.checkName.toString();
    },
    activeItem(val) {
      this.treeName = null;
      this.checkName = [];
      this.selection = val;
      this.showName(this.items);
      if (val.length > 0) {
        setTimeout(() => {
          this.show = false;
        }, 500);
      }
      this.$emit('change', val);
    },
    checkItem(val) {
      this.treeName = null;
      this.checkName = [];
      this.selection = val;
      this.showName(this.items);
      // if (val.length > 0) {
      //   setTimeout(() => {
      //     this.show = false;
      //   }, 500);
      // }
      this.$emit('change', val);
    },
    onClear() {
      this.treeName = null;
      this.checkName = [];
      this.selection = [];
      this.$emit('change', []);
    },
  },
};
</script>
<style lang="scss" scoped>
.input-field {
  width: var(--inputWidth);
}
.input-label {
  width: var(--labelWidth);
}
//让错误浮动的显示在入力框下面，并不占用页面空间。
::v-deep .v-text-field__details {
  position: absolute;
  top: 3em;
}

::v-deep .v-treeview-node__label {
  font-size: 14px;
}
</style>
