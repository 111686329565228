import http from '../http';

const path = '/fds/api/data';
export default {
  getversion(data) {
    return http.get(path + '/version', data);
  },
  gettimesettingn(data) {
    return http.get(path + '/timesetting', data);
  },
  getAddTime(data) {
    return http.post(path + '/upgrade/timesetting', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  getAddList(data) {
    return http.post(path + '/upgrade/version', data, {
      'Content-Type': 'application/json;charset=UTF-8',
    });
  },
  getdeleteCamera(data) {
    return http.delete(path + `/upgrade/version/${data}`, {});
  },
};
